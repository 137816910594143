import { Form as FormikForm, Formik } from "formik";
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../components/FormInput';
import { validationSchema } from '../../validations/consumercategory.validation';
import { Button } from "react-bootstrap";
import { handleAddConsumerCategory } from "../../services/consumercategory.service";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const ConsumerCategoryModal = ({ modal, toggle }) => {
    const navigate = useNavigate();
    const onSubmit = async (values) => {
        console.log("values::", values);
        handleAddConsumerCategory(values).then(response => {
            console.log("Add Consumer Category::", response);
            toast.success(response.data.message);
            navigate("/consumer-categories");
        }).catch((error) => {
            toast.error(error.response.data.detail);
        });
    }

    return (
        <Modal className="consumer-category-modal-cover" isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='border-0 fs-16 fw-semibold' toggle={toggle}>Add Consumer Category</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        categoryName: "",
                        description: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <FormikForm>
                            <FormInput
                                error={errors.categoryName}
                                id="categoryName"
                                key={"categoryName"}
                                label="Category Name"
                                name="categoryName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter category name"
                                touched={touched.categoryName}
                                type="text"
                                value={values.categoryName || ""}
                            />

                            <FormInput
                                error={errors.description}
                                id="description"
                                key={"description"}
                                label="Description"
                                name="description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter description"
                                touched={touched.description}
                                type="text"
                                value={values.description || ""}
                            />
                        </FormikForm>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter className='border-0'>
                <Button className="fs-14 fw-semibold" variant="outline-dark" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button className="fs-14 fw-semibold" variant="primary" onClick={onSubmit}>
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConsumerCategoryModal;
