import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import FormInput from "../../components/FormInput";
import ReactSelect from "../../components/ReactSelect";
import { HiMiniUsers } from 'react-icons/hi2';
import { handleGetConsumerCategories } from '../../services/consumercategory.service';
import { handleGetDistricts } from '../../services/district.service';
import { handleGetStates } from '../../services/state.service';
import SvgIcons from "../../components/SVGIcons"
const SearchForm = ({ filter, setFilter }) => {
    const [districtOptions, setDistrictOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    useEffect(() => {
        // Fetch consumer categories
        handleGetConsumerCategories().then(response => {
            setCategoryOptions(response.data.data.map(category => ({
                value: category.id,
                label: category.categoryName,
            })));
        });

        // Fetch districts
        handleGetDistricts().then(response => {
            setDistrictOptions(response.data.data.map(district => ({
                value: district.id,
                label: district.districtName,
            })));
        });

        // Fetch states
        handleGetStates().then(response => {
            setStateOptions(response.data.data.map(state => ({
                value: state.id,
                label: state.stateName,
            })));
        });
    }, []);

    const customerLevelOptions = [
        { value: 'Intermediary', label: 'Intermediary' },
        { value: 'End Consumer', label: 'End Consumer' },
    ];

    const supplyToOptions = [
        { value: 'Domestic', label: 'Domestic' },
        { value: 'Non-Domestic', label: 'Non Domestic' },
    ];

    return (
        <div className="theme-card-header px-1 header-search">
            <Stack
                gap={3}
                className="flex-wrap px-3 pt-3 align-items-start"
            >
                <h5 className="mb-0 position-relative fw-semibold fs-16">
                    <div className="align-items-center bg-black d-inline-flex custom-height-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box custom-width-60 z-1">
                    <span className='page-header-icon'>{SvgIcons.consumerIcon}</span>
                    </div>
                    Consumer List
                </h5>
                <div className="w-100">
                    <Row className="g-3">
                        <Col md={2} className="d-flex">
                            <div className="custom-width-250 flex-grow-1 flex-md-grow-0">
                                <FormInput
                                    id="search"
                                    key={"search"}
                                    name="search"
                                    placeholder="Search"
                                    type="text"
                                    onChange={(event) => {
                                        if (event.target.value === "") {
                                            setFilter({
                                                ...filter,
                                                search: undefined,
                                            });
                                            return;
                                        }
                                        setFilter({
                                            ...filter,
                                            search: event.target.value,
                                        });
                                    }}
                                    value={filter.search}
                                />
                            </div>
                        </Col>
                        <Col md={10} className="d-flex justify-content-end">
                            <Row className="justify-content-end gx-2">
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="district"
                                        key="district"
                                        name="district"
                                        label=""
                                        placeholder="All Districts"
                                        value={filter.district}
                                        options={districtOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                districtId: option ? option.target.value : '',
                                                district: option ? option.target.value : ''
                                            });
                                        }}
                                    
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="state"
                                        key="state"
                                        name="state"
                                        label=""
                                        placeholder="All States"
                                        value={filter.state}
                                        options={stateOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                stateId: option ? option.target.value : '',
                                                state: option ? option.target.value : '',
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="customerLevel"
                                        key="customerLevel"
                                        name="customerLevel"
                                        label=""
                                        placeholder="Customer Level"
                                        value={filter.customerLevel}
                                        options={customerLevelOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                customerLevels: option ? option.target.value : '',
                                                customerLevel: option ? option.target.value : '',
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="supplyTo"
                                        key="supplyTo"
                                        name="supplyTo"
                                        label=""
                                        placeholder="All Supply To"
                                        value={filter.supplyTo}
                                        options={supplyToOptions}
                                        onChange={(option) => {
                                            console.log("Supply",option);
                                            setFilter({
                                                ...filter,
                                                furtherSupplies: option ? option.target.value : '',
                                                supplyTo: option ? option.target.value : ''
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="category"
                                        key="category"
                                        name="category"
                                        label=""
                                        placeholder="All Category"
                                        value={filter.category}
                                        options={categoryOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                categoryId: option ? option.target.value : '',
                                                category: option ? option.target.value : '',
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        
                        
                    </Row>
                </div>
            </Stack>
        </div>
    );
};

export default SearchForm;
