import * as Yup from "yup";

const validationSchema = Yup.object({
    categoryName: Yup.string().max(50, 'Maximum limit is 50')
        .required("Category name is required."),
    categoryCode: Yup.string().max(10, 'Maximum limit is 10').required("Category code is required.")
    .matches(/^[a-zA-Z0-9]+$/, "It must contain only letters and numbers."),
    description: Yup.string().max(255, 'Maximum limit is 255')
});

export { validationSchema };
