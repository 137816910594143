import { Form as FormikForm, Formik } from "formik";
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../components/FormInput';
import { validationSchema } from '../../validations/templatemanagement.validation';
import { Button } from "react-bootstrap";
import Toggle from '../../components/Toggle';
import { handleAddTemplate } from "../../services/templatemanagement.service";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const TemplateManagementModal = ({ modal, toggle }) => {
    const navigate = useNavigate();
    
    const onSubmit = async (values) => {
        handleAddTemplate(values).then(response => {
            toast.success(response.data.message);
            navigate("/templates");
        }).catch((error) => {
            toast.error(error.response.data.detail);
        });
    };

    return (
        <Modal className="template-management-modal-cover" isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='border-0 fs-16 fw-semibold' toggle={toggle}>Add Template</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        templateName: "",
                        status: true,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <FormikForm>
                            <FormInput
                                error={errors.templateName}
                                id="templateName"
                                key={"templateName"}
                                label="Template Name"
                                name="templateName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter template name"
                                touched={touched.templateName}
                                type="text"
                                value={values.templateName || ""}
                            />

                            <Toggle
                                id="status"
                                key={"status"}
                                label="Status"
                                name="status"
                                onChange={handleChange}
                                value={values.status}
                            />
                        </FormikForm>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter className='border-0'>
                <Button className="fs-14 fw-semibold" variant="outline-dark" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button className="fs-14 fw-semibold" variant="primary" onClick={onSubmit}>
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TemplateManagementModal;
