import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ toggle }) => {
    const navigate = useNavigate();
    const companyTitle = JSON.parse(localStorage.getItem("companyTitle"));
    return (
        <div className="contentHeader p-1">
            <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between custom-min-height-42"
            >
                <h1 className="fw-semibold h4 mb-0 fs-22">
                    Vendor Subsidy Data Management
                </h1>
                <div>
                    {
                        companyTitle != 'GAIL' ? 
                        <Link to={`/subsidy-vendor-data/upload`} className="fw-semibold fs-14 custom-min-width-100 bg-info text-white text-decoration-none rounded-2 p-2 text-center" type="button" variant="info" size="sm">Upload Data File</Link>
                        : ''    
                     }
                </div>
            </Stack>
        </div>
    );
};

export default Header;
