import React from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import FormInput from "../../components/FormInput";
import { HiMiniUsers } from 'react-icons/hi2';
import ReactSelect from '../../components/ReactSelect';
import DateRangePicker from '../../components/DateRangePicker';
import SvgIcons from "../../components/SVGIcons"
const SearchForm = ({ financialYearOptions, companyOptions, filter, setFilter, monthOptions }) => {
    return (
        <div className="theme-card-header px-1 header-search">
            <Stack
                gap={3}
                className="flex-wrap px-3 pt-3  align-items-start"
            >
                <h5 className="mb-0 position-relative fw-semibold fs-16">
                    <div className="align-items-center bg-black d-inline-flex custom-height-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box custom-width-60 z-1">
                    <span className='page-header-icon'>{SvgIcons.neSubsidy}</span>
                    </div>
                   Website Listing
                </h5>
                <div className="w-100">
                    <Row className="g-3">
                        <Col md={4} className="d-flex">
                            <div className="custom-width-250 flex-grow-1 flex-md-grow-0">
                                <FormInput

                                    id="search"
                                    key={"search"}
                                    name="search"
                                    placeholder="Search"
                                    type="text"
                                    onChange={(event) => {
                                        if (
                                            event.target
                                                .value ===
                                            ""
                                        ) {
                                            setFilter({
                                                ...filter,
                                                search: undefined,
                                            });
                                            return;
                                        }
                                        setFilter({
                                            ...filter,
                                            search: event.target.value,
                                        });
                                    }}
                                    value={filter.search}

                                />
                            </div>
                        </Col>
                        <Col>
                            <Row className="align-items-start g-2 justify-content-end row">
                                
                                <Col sm md="auto" className="d-flex" >

                                     <div className=" flex-grow-1 flex-md-grow-0 min-width-155">
                                        
                                            <ReactSelect
                                                label={""}
                                                id="company"
                                                key="company"
                                                name="company"
                                                class="form-select "
                                                placeholder="All Company"
                                                aria-label="Floating label select example"
                                                options={companyOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        companyId: e.target.value,
                                                        company: e.target.value ? e.target.value : ''
                                                    });
                                                }}
                                                value={filter.company}
                                            />  

                                    </div>
                                </Col>

                               
                                <Col sm md="auto" className="d-flex" >
                                    <div className=" flex-grow-1 flex-md-grow-0 min-width-155">
                                            <ReactSelect
                                                label={""}
                                                id="financialYear"
                                                key="financialYear"
                                                name="financialYear"
                                                class="form-select "
                                                placeholder="All Financial Years"
                                                aria-label="Floating label select example"
                                                options={financialYearOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        financialYearId: e.target.value,
                                                        financialYear: e.target.value ? e.target.value : ''
                                                    });
                                                }}
                                                value={filter.financialYear}
                                            />
                                    </div>   
                                </Col>
                                <Col sm md="auto" className="d-flex" >
                                    <div className=" flex-grow-1 flex-md-grow-0  min-width-155">
                                            <ReactSelect
                                                label={""}
                                                id="month"
                                                key="month"
                                                name="month"
                                                class="form-select "
                                                placeholder="All Months"
                                                aria-label="Floating label select example"
                                                options={monthOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        month: e.target.value,
                                                    });
                                                }}
                                                value={filter.month}
                                            />
                                    </div>
                                </Col>
                                
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Stack>
        </div>
    );
};

export default SearchForm;
