import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    margin: 20,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const ImageSlider = ({ newsData }) => {
  return (
    <div className="parent">

      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {newsData.map((row, index) => {
          return (
            <Link to={`/news-details/${row.slug}`}  className="text-black text-decoration-none">
              <div className="slider-bg">
              <div className="slider" key={index}>
                <img src={row.imageUrl} alt="movie" />
                <div className="fs-18 fw-semibold mt-3 mb-3 text-white text-center px-2">{row.title}</div>
                <p className="mb-0 fs-14 text-white text-center px-2 truncate">
                  {row.shortDescription}
                </p>
              </div>
            </div>
            </Link>

          );
        })}
      </Carousel>
    </div>
  );
};
export default ImageSlider;
