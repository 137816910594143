import instance from "../utils/instance";

export const getListingData = async (params) => {
    return await instance.get('/v1/subsidy-data-upload', {
        params
    });
}

export const handleDeleteNonDomesticConsumer = async (id) => {
    return await instance.delete(`/v1/subsidy-data-upload/${id}`);
}

export const handleGetSpecificDataById = async (id) => {
    return await instance.get(`/v1/subsidy-data-upload/${id}`);
}

export const handleAddData = async (data) => {
    return await instance.post('/v1/subsidy-data-upload', data);
}

export const handleEditData = async (id, data) => {
    data.id = id;
    return await instance.post(`/v1/subsidy-data-upload/review`, data);
}

export const handleVendorEditData = async (id, data) => {
    data.id = id;
    return await instance.post(`/v1/vendor-subsidy-data-upload/review`, data);
}

export const handleImportConsumerData = async(formData)=>{
    return await instance.post('/v1/subsidy-data-upload/import', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
