import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import SvgIcons from "../../components/SVGIcons";
import {
    downloadFile,
    handleGetUsers,
    handleGetCompany,
    handleGetFinancialYear,
    handleGetClaimById, // Import the new function
} from "../../services/claim.service";
import Header from "./Header";
import SearchForm from "./SearchForm";
import UsersTable from "./UsersTable";
import Toggle from "../../components/Toggle";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";

export default function WebsiteListing() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });

    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        companyId: "",
        company: "",
        financialYear: "",
        financialYearId: "",
        month: "",
    });



    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return handleGetUsers({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return handleGetUsers({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });
    const [companyOptions, setCompanyOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([
        { label: 'Select month', value: '' },
        { label: "January", value: "Jan" },
        { label: "February", value: "Feb" },
        { label: "March", value: "Mar" },
        { label: "April", value: "Apr" },
        { label: "May", value: "May" },
        { label: "June", value: "Jun" },
        { label: "July", value: "Jul" },
        { label: "August", value: "Aug" },
        { label: "September", value: "Sep" },
        { label: "October", value: "Oct" },
        { label: "November", value: "Nov" },
        { label: "December", value: "Dec" }
    ]);
    const [userFilePath, setUserFilePath] = useState();
    const [copyUserDetailsQuery, setCopyUserDetailsQuery] = useState();

    const permission = useRef({ addModule: false, editModule: false, deleteModule: false, addModuleA: false, addModuleB: false });
    useEffect(() => {
        isAdminUser().then(response => {
            if (response) {
                permission.current.addModuleA = true;
                permission.current.addModuleB = true;
                permission.current.addModule = true;
                permission.current.editModule = true;
                permission.current.deleteModule = true;
            } else {
                getModulePermissions("NE Subsidy Claim Statement").then(response => {
                    if (response.includes("NE_SUBSIDY_CLAIM_STATEMENT_CREATE")) {
                        permission.current.addModule = true;
                    }
                    if (response.includes("NE_SUBSIDY_CLAIM_STATEMENT_VIEW")) {
                        permission.current.editModule = true;
                    }
                    if (response.includes("SUBSIDY_DATA_FILE_DELETE")) {
                        permission.current.deleteModule = true;
                    }
                }).catch(error => {
                    console.error("Error fetching permissions:", error);
                });
            }
        }).catch(error => {
            console.error("Error get during to fetch User Type", error);
        })

    }, []);

    useEffect(() => {

        // Get company list
        handleGetCompany().then(response => {
            console.log('companyOptions',response.data?.data)
            let companiesList = [{ value: '', label: 'Select company' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    companiesList.push({ value: category?.id, label: category?.title });
                });
                setCompanyOptions(companiesList);
            }
        });
        
        // Get financial year list
        handleGetFinancialYear().then(response => {
            let financialYearList = [{ value: '', label: 'Select financial year' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    financialYearList.push({ value: category?.id, label: category?.financialYear });
                });
                setFinancialYearOptions(financialYearList);
                setLoading(false);
            }
        });

    }, []);

    // useEffect(() => {
    //     const queryyy =  useQuery({
    //         queryKey: ["userDetail", selectedUserId],
    //         queryFn: () => handleGetClaimById(selectedUserId),
    //         enabled: !!selectedUserId, // Only run this query if selectedUserId is set
    //     })
    //     setCopyUserDetailsQuery(queryyy)
    // },[]);

    // useEffect(() => {
    //     if(copyUserDetailsQuery) setUserFilePath(JSON.parse(copyUserDetailsQuery.data.data.data.filePathsList))
    // }, [copyUserDetailsQuery])



    // Query to fetch user details by ID
    const userDetailQuery = useQuery({
        queryKey: ["userDetail", selectedUserId],
        queryFn: () => handleGetClaimById(selectedUserId),
        enabled: !!selectedUserId, // Only run this query if selectedUserId is set
    });


    console.log('userDetailQuery', userDetailQuery)
    console.log('copyUserDetailsQuery', copyUserDetailsQuery)
    console.log('userFilePath', userFilePath)
    // Handle last page deletion item
    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
        setLoading(false)
    }, [dataQuery.data?.data?.totalPages]);


    const handleEyeIconClick = (user) => {
        setSelectedUserId(user.id);
        setShowModal(true);
    };

    const columns = React.useMemo(() => [
        {
            accessorFn: (row) => row.financialYear?.financialYear,
            id: "financialYear",
            header: () => "Financial Year",
        },
        {
            accessorFn: (row) => row.month,
            id: "month",
            header: () => "Month",
        },
        {
            accessorFn: (row) => row.company?.title,
            id: "company",
            header: () => "Company",
        },
        {
            accessorFn: (row) => row.createdAt,
            id: "createdAt",
            header: () => "Updated At",
            cell: (info) => {
                return (
                    <span>
                        {moment(info.row.original.createdAt).format(
                            "D/M/YYYY hh:mm A"
                        )}
                    </span>
                );
            },
        },
        {
            accessorFn: (row) => row.uploadedBy,
            id: "uploadedBy",
            header: () => "Uploaded By",
        },
        {
            id: "actions",
            isAction: true,
            cell: (info) => {
                return (
                    <div className="d-flex items-center gap-2 pointer">
                    {permission.current.editModule ?
                        <div onClick={() => handleEyeIconClick(info.row.original)}>

                            {SvgIcons.eyeIcon}

                        </div>: ""}
                    </div> 
                );
            },
            header: () => "Actions",
            enableSorting: false,
        },
    ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    // Download file
    const handleDownloadPdf = async (file) => {
        // Get company list
        console.log('fileKey', file)
        await downloadFile(file).then(response => {
            
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file; // Use the provided file name or adjust as necessary
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

        });
    }

    const parseFilePaths = (obj) => {
        let pdfPath = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                pdfPath.push(obj[key]);
                // break; // Assuming there's only one key-value pair, we can break after finding the first one
            }
        }
        return pdfPath;
    };

    const getFileName = (url) => {
        const parts = url.split('/');
        return parts[parts.length - 1];
    };

    return (
        <React.Fragment>
            {loading ? <Loader isLoading={loading} /> :
                <>
                    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                        {/* <Header /> */}
                        {permission.current.addModule?<Header/>:""}
                        <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                            <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                                <SearchForm monthOptions={monthOptions} financialYearOptions={financialYearOptions} companyOptions={companyOptions} filter={filter} setFilter={setFilter} />
                                

                                <div className="flex-grow-1 d-flex flex-column px-3 pb-1 overflow-auto">
                                    <div className="p-1 pt-0 h-100">
                                    <table className="table table-sm table-bordered table-striped table-hover custom-table ">
                                        <thead>
                                            <tr>
                                                <th>Particulars</th>
                                                <th></th>
                                                <th>Qty Uploaded by OIL in Sheet  (In MSCM)</th>
                                                <th>Break up of End Consumber provided by AGCL</th>
                                                <th>Source</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        
                                    <tr>
                                        <td className="fw-bolder">End Consumer</td>
                                        <td>Brahamputra Valley Fertilizer Corporation Ltd.</td>
                                        <td> 31,900.00 </td>
                                        <td> Not applicable </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Hotel Mayuri</td>
                                        <td> 1.55 </td>
                                        <td> Not applicable </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Based Power Proj (NEEPCO)</td>
                                        <td> 31,000.00 </td>
                                        <td> Not applicable </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Power Generation Corporation Ltd. (NTPS)</td>
                                        <td> 20,179.09 </td>
                                        <td> Not applicable </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder">Intermediaries: -</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: (Comp)</td>
                                        <td> 356.50 </td>
                                        <td></td>
                                        <td>AGCL-Non Domestic</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: RS(NHK)</td>
                                        <td> 1,699.19 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: Santi</td>
                                        <td> 120.16 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: SVTE</td>
                                        <td> 30.00 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: DDGG</td>
                                        <td> 3,099.87 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: DTGG</td>
                                        <td> 3,641.14 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: MTGG</td>
                                        <td> 1,126.72 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: MPGG</td>
                                        <td> 1,482.81 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: JGGG</td>
                                        <td> 1,338.62 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd. :TGPE (Derial)</td>
                                        <td> 422.95 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd. :TGPE (Dikom)</td>
                                        <td> 324.89 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd. :TGG (OCS 4)</td>
                                        <td> 1,131.94 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd. :TGG (Derial)</td>
                                        <td> 471.54 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total Non Domestic</td>
                                        <td> 15,246.32 </td>
                                        <td> 15,246.317 </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: RS(NHKD)</td>
                                        <td> 31.16 </td>
                                        <td></td>
                                        <td>AGCL-Domestic</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: (MD)</td>
                                        <td> 59.19 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: DTG</td>
                                        <td> 186.00 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: DTGG(D)</td>
                                        <td> 254.75 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: MTGG(D)</td>
                                        <td> 130.20 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Assam Gas Company Ltd.: JGGG(D)</td>
                                        <td> 45.32 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total Domestic</td>
                                        <td> 706.62 </td>
                                        <td> 706.622 </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bolder">TOTAL</td>
                                        <td></td>
                                        <td> 99,033.58 </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>As per Claim sheet submitted to PPAC</td>
                                        <td className="fw-bolder"> 99,033.58</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <Modal centered show={showModal} onHide={() => setShowModal(false)} className="templateDetailModal">
                            <Modal.Header closeButton>
                                <Modal.Title>Template Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {userDetailQuery.isLoading && <p>Loading...</p>}
                                {userDetailQuery.isError && <p>Error loading user details</p>}
                                {userDetailQuery.data && (
                                    <div className="modalInner">
                                        <div className="orderCaret">
                                            <div className="row">
                                                <div className="col"><p className="d-flex align-items-start"><strong>Company</strong> {userDetailQuery.data.data.data.company?.title}</p></div>
                                                <div className="col"><p className="d-flex align-items-start"><strong>Financial Year</strong> {userDetailQuery.data.data.data.financialYear?.financialYear}</p></div>
                                                <div className="col"><p className="d-flex align-items-start"><strong>Month</strong> {userDetailQuery.data.data.data.month}</p></div>
                                            </div>
                                        </div>

                                        <div className="orderCaret">
                                            <p><strong>Data File(s)</strong></p>
                                            <div className="row">


                                                {parseFilePaths(userDetailQuery.data.data.data.filePathsList).map((url) => {
                                                    return <div className="col-12">
                                                        <div className="fileArea">
                                                            <span className="fileIco">
                                                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.5 15.5C1.0875 15.5 0.734375 15.3531 0.440625 15.0594C0.146875 14.7656 0 14.4125 0 14V2C0 1.5875 0.146875 1.23438 0.440625 0.940625C0.734375 0.646875 1.0875 0.5 1.5 0.5H8.25L12 4.25V14C12 14.4125 11.8531 14.7656 11.5594 15.0594C11.2656 15.3531 10.9125 15.5 10.5 15.5H1.5ZM1.5 14H10.5V5H7.5V2H1.5V14ZM6 13.25C6.8375 13.25 7.54688 12.9563 8.12813 12.3687C8.70938 11.7812 9 11.075 9 10.25V7.25H7.5V10.25C7.5 10.6625 7.35625 11.0156 7.06875 11.3094C6.78125 11.6031 6.425 11.75 6 11.75C5.5875 11.75 5.23438 11.6031 4.94063 11.3094C4.64687 11.0156 4.5 10.6625 4.5 10.25V6.125C4.5 6.0125 4.5375 5.92188 4.6125 5.85313C4.6875 5.78438 4.775 5.75 4.875 5.75C4.9875 5.75 5.07812 5.78438 5.14688 5.85313C5.21563 5.92188 5.25 6.0125 5.25 6.125V10.25H6.75V6.125C6.75 5.6 6.56875 5.15625 6.20625 4.79375C5.84375 4.43125 5.4 4.25 4.875 4.25C4.35 4.25 3.90625 4.43125 3.54375 4.79375C3.18125 5.15625 3 5.6 3 6.125V10.25C3 11.075 3.29375 11.7812 3.88125 12.3687C4.46875 12.9563 5.175 13.25 6 13.25Z" fill="#0088FF" />
                                                                </svg>
                                                            </span>
                                                            <a href="javascript:void(0)" onClick={() => handleDownloadPdf(url)}>
                                                                {/* {url} */}
                                                                {getFileName(url)}
                                                            </a>
                                                        </div>
                                                    </div>
                                                })}





                                            </div>
                                        </div>



                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)} className="footerBtnSubmit fs-14 bg-info text-white text-decoration-none rounded-2 p-2 text-center">
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            }
        </React.Fragment>
    );
}
