import React, { useEffect, useState } from "react"
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import ReactSelect from "react-select"
import Gaav from "../../assets/images/gaav.png"
import account_circle from "../../assets/images/account_circle.svg"
import calendar_today from "../../assets/images/calendar_today.svg"
import seminews from "../../assets/images/seminews.png"
import {
  handleGetRecentNews,
  handleGetnewsBySlug
} from "../../services/website.service";
import { Link, useParams, useNavigate } from "react-router-dom"
import moment from "moment"
import Loader from "../../components/Loader";
import { I18nextProvider, useTranslation } from "react-i18next"

export default function Newsupdatedetails() {

  // const params = useParams();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const isEdit = !!slug;
  const [newsDetail, setNewsDetail] = useState([]);
  const [recentNewsList, setRecentNewsList] = useState([]);
  const [filter, setFilter] = useState({})
  const navigate = useNavigate();

  const { i18n } = useTranslation()

    const { t } = useTranslation()

  const onSubmit = async (values, actions) => { }

  useEffect(() => {
    if (slug) {
      setLoading(true);
      // handleGetnewsBySlug(slug).then(response => {
      //   setNewsDetail(response.data.data)
      //   setLoading(false);
      // });

      handleGetnewsBySlug(slug)
      .then(response => {
        setNewsDetail(response.data.data);
      })
      .catch(error => {
        navigate("/home");
      })
      .finally(() => {
        setLoading(false);
      });

      handleGetRecentNews().then((response) => {
        let recentNewsListArr = [];
        if (response.data?.data?.length > 0) {
          response.data?.data?.forEach((category) => {
            recentNewsListArr.push({
              slug: category?.slug,
              title: category?.title,
              shortDescription: category?.shortDescription,
              imageUrl: category?.imageUrl
            });
          });
          setRecentNewsList(recentNewsListArr);
        }
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }, [slug, isEdit, i18n?.language]);

  return (
    <>

      {loading ? <Loader isLoading={loading} /> :

        <section className="Newsupdatedetails-cover">
          <Container>
            <Row className="d-flex ">
              <Col md={8}>
                <div className="mb-4">
                  <Image
                    className="img-fluid"
                    src={newsDetail.imageUrl}
                    alt={`Logo`}

                  />
                </div>
                <h2 className="fs-32 fw-semibold">
                  {newsDetail.title}
                </h2>
                <div className="d-flex">
                  <div className="align-items-center color-brown d-flex fs-14">
                    <span className="mr3">
                      <Image
                        className="img-fluid me-2"
                        src={account_circle}
                        alt={`account_circle`}
                      />
                    </span>
                    {newsDetail.author}
                  </div>

                  <div className="align-items-center color-brown d-flex fs-14 ms-3">
                    <span className="mr3">
                      <Image
                        className="img-fluid me-2"
                        src={calendar_today}
                        alt={`calendar_today`}
                      />
                    </span>
                    {moment(newsDetail.createdAt).format(
                      "D/M/YYYY hh:mm A"
                    )}
                    {/* {newsDetail.createdAt} */}
                  </div>
                </div>

                <p className="mt-3">
                  {newsDetail.shortDescription}
                  <br></br>
                  <br></br>
                  {newsDetail.content}
                </p>
              </Col>
              <Col md={4}>
                <h3 className="fs-24 fw-semibold mb-4">Recent News</h3>
                {recentNewsList.map((row, index) => {
                  return (
                    <Link to={`/news-details/${row.slug}`} className="text-black text-decoration-none">
                      <div className="semi-news-cover d-flex">
                        <div className="left-one me-3">
                          <Image
                            className="img-fluid me-2"
                            src={row.imageUrl}
                            alt={`seminews`}
                          />
                        </div>
                        <div className="right-one">
                          <div className="fw-semibold">{row.title}</div>
                          <p className="text-ellipsis">{row.shortDescription}</p>
                        </div>
                      </div>
                    </Link>
                  );
                })}


              </Col>
            </Row>
          </Container>
        </section>
      }

    </>
  )
}
