import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import SvgIcons from "../../components/SVGIcons";
import { PieChart } from "@mui/x-charts/PieChart";
import BarChartModel from "./barChart/index";
import {
    handleGetStateList,
    getSubsidyListingData,
    handleGetCompany,
    handleGetFinancialYear,
    getSubsidyGraphListingData,
} from "../../services/website.service";
import Header from "./Header";
import SearchForm from "./SearchForm";
import Toggle from "../../components/Toggle";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";
import { I18nextProvider, useTranslation } from "react-i18next"

export default function SubsidyGasProgramGraph() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });

    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        supplyId: "",
        company: "",
        financialYear: "",
        financialYearId: "",
        month: "",
        stateId: "",
        saleLocationId: "",
        districtId: ""
    });

    const [pieChartData, setPieChartData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [sortedData, setSortedData] = useState([]); // Table

    const [selectedUserId, setSelectedUserId] = useState(null);

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return getSubsidyListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return getSubsidyListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });
    const [stateOptions, setStateOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [fixCompanyOptions, setFixCompanyOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([
        { label: 'Select month', value: '' },
        { label: "January", value: "Jan" },
        { label: "February", value: "Feb" },
        { label: "March", value: "Mar" },
        { label: "April", value: "Apr" },
        { label: "May", value: "May" },
        { label: "June", value: "Jun" },
        { label: "July", value: "Jul" },
        { label: "August", value: "Aug" },
        { label: "September", value: "Sep" },
        { label: "October", value: "Oct" },
        { label: "November", value: "Nov" },
        { label: "December", value: "Dec" }
    ]);

    const { i18n } = useTranslation()

    const { t } = useTranslation()

    useEffect(() => {
        setLoading(true);
        // Get company list
        handleGetCompany().then(response => {
            let companiesList = [{ value: '', label: 'Select company' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    companiesList.push({ value: category?.id, label: category?.title });
                });
                setCompanyOptions(companiesList);

                console.log('companyOptions', companyOptions)
                //setFixCompanyOptions(companyOptions)
                setFixCompanyOptions(companiesList.filter(item => (['Select company', 'ONGC', 'OIL'].includes(item.label))))
                setLoading(false);
            }
        });
        // Get financial year list
        handleGetFinancialYear().then(response => {
            let financialYearList = [{ value: '', label: 'Select financial year' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    financialYearList.push({ value: category?.id, label: category?.financialYear });
                });
                setFinancialYearOptions(financialYearList);
                //setLoading(false);
            }
        });

        // Get financial year list
        handleGetStateList().then(response => {
            let financialYearList = [{ value: '', label: 'Select state' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    financialYearList.push({ value: category?.id, label: category?.stateName });
                });
                setStateOptions(financialYearList);
                setLoading(false);
            }
        });

    }, []);

    const fetchDashboardData = async (filter) => {
        const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
        Object.keys(filterObj).forEach((key) => filterObj[key] === "" && delete filterObj[key]);

        const response = await getSubsidyGraphListingData(filterObj);
        setBarChartData(response.data.data.gasBenefitByState);
        setPieChartData(response.data.data.gasBenefitByConsumerCategory);
        setSortedData(response.data.data.gasBenefitByConsumerCategory.sort((a, b) => b.value - a.value));
    };

    useEffect(() => {
        fetchDashboardData(filter);
    }, [filter]);

    const totalValue = sortedData.reduce((acc, item) => acc + item.value, 0).toFixed(2);
    return (
        <React.Fragment>
            {loading ? <Loader isLoading={loading} /> :
                <>
                    <div className="d-flex flex-column pageContainer h-100 chart-area-tab">
                        <div className="flex-grow-1 pageContent position-relative">
                            <div className="h-100 bg-white border-0 theme-card-cover">
                                <SearchForm
                                    monthOptions={monthOptions}
                                    financialYearOptions={financialYearOptions}
                                    companyOptions={fixCompanyOptions}
                                    filter={filter}
                                    setFilter={setFilter}
                                    stateOptions={stateOptions}
                                />

                            </div>
                            {/* <Card>
                                <PieChart
                                    series={[
                                        {
                                            data: pieChartData,
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                />

                                <BarChartModel chartData={barChartData} />
                            </Card> */}
                        </div>


                    </div>
                    <div className="chart-area-tab">
                        <div className="row">
                            <div className="mb-3 col-md-12 col-lg-6">
                                <Card>
                                    <div className="h-100 border-0 p-3 custom-shadow card">
                                        <h6 class="fw-semibold bg-body border-0 card-header"><h2 class="fs-16 fw-bolder">{t("GAS SUBSIDY BENEFIT BY CONSUMER CATEGORY")}</h2></h6>
                                        <div className="d-fex row">
                                            <div className="mb-3 col-lg-8 pie_width_height">

                                                <PieChart
                                                    series={[
                                                        {
                                                            cx: 200,
                                                            cy: 150,
                                                            innerRadius: 0,
                                                            outerRadius: 100,
                                                            paddingAngle: 0,
                                                            cornerRadius: 0,
                                                            startAngle: -169,
                                                            endAngle: 200,
                                                            data: pieChartData,
                                                            highlightScope: { faded: 'global', highlighted: 'item' },
                                                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                            valueFormatter: (v) => (v === null ? '' : v.value + ' Cr.'),
                                                        },

                                                    ]}
                                                    slotProps={{
                                                        legend: {
                                                            direction: 'row',
                                                            position: { vertical: 'bottom', horizontal: 'middle' },
                                                            padding: 0,
                                                            hidden: true,  //  You can hide the legend with the property slotProps.legend.hidden.
                                                            //margin: { top: 0, bottom: 80, left: 0, right: 30 },
                                                        },
                                                    }}
                                                    margin={{ top: -50, bottom: 100, left: 0, right: 30 }}

                                                // width={700}
                                                // height={300}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <table className="table table-sm table-bordered border table-hover dashboard-table" style={{overflow:"inherit"}}>
                                                    <thead className="">
                                                        <tr>
                                                            <th className="text-start text-center">
                                                                Category
                                                            </th>
                                                            <th className="text-start text-center">
                                                                Rs in Crore
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sortedData.map((item) => (
                                                            <tr key={item.id}>
                                                                <td className="text-start border  text-truncate" style={{
                                                                    background: item.color,
                                                                    color: "#fff",
                                                                    maxWidth: "50px",
                                                                }}  title={item.fullLabel} >{item.fullLabel}</td>
                                                                <td className="text-start border">{item.value.toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td
                                                                className="text-start border"
                                                                style={{
                                                                    background: '#fff',
                                                                    color: "#000",
                                                                    'font-weight': "600",
                                                                }}
                                                            >
                                                                Total
                                                            </td>
                                                            <td className="text-start border">{totalValue}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="mb-3 col-md-12 col-lg-6">

                                <Card>
                                    <div className="h-100 border-0 p-3 custom-shadow card">
                                        <h6 class="fw-semibold bg-body border-0 card-header"><h2 class="fs-16 fw-bolder">{t("GAS SUBSIDY BENEFIT BY STATE")}</h2></h6>
                                        {/* <BarChartModel chartData={barChartData} /> */}
                                        <div className="d-flex align-items-center">
                                            <div className="dashbord-rotate-text dashbord-rotate-text-Subsidy">
                                                <span>Subsidy - Rs in Crore</span>
                                            </div>
                                            <div className="w-100 ">
                                                <BarChartModel chartData={barChartData} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                            </div>
                        </div>
                    </div>
                </>
            }
        </React.Fragment>
    );
}
