import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import SvgIcons from "../../components/SVGIcons";
import {
    
    getSubsidyListingData,
    handleGetCompany,
    handleGetFinancialYear
} from "../../services/website.service";
import Header from "./Header";
import SearchForm from "./SearchForm";
import UsersTable from "./UsersTable";
import Toggle from "../../components/Toggle";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";

export default function SubsidyGasProgram() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });

    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        companyId: "",
        company: "",
        financialYear: "",
        financialYearId: "",
        month: "",
    });



    
    const [selectedUserId, setSelectedUserId] = useState(null);

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return getSubsidyListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return getSubsidyListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });
    const [companyOptions, setCompanyOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([
        { label: 'Select month', value: '' },
        { label: "January", value: "Jan" },
        { label: "February", value: "Feb" },
        { label: "March", value: "Mar" },
        { label: "April", value: "Apr" },
        { label: "May", value: "May" },
        { label: "June", value: "Jun" },
        { label: "July", value: "Jul" },
        { label: "August", value: "Aug" },
        { label: "September", value: "Sep" },
        { label: "October", value: "Oct" },
        { label: "November", value: "Nov" },
        { label: "December", value: "Dec" }
    ]);
    

    

    useEffect(() => {

        // Get company list
        handleGetCompany().then(response => {
            let companiesList = [{ value: '', label: 'Select company' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    companiesList.push({ value: category?.id, label: category?.title });
                });
                setCompanyOptions(companiesList);
            }
        });
        // Get financial year list
        handleGetFinancialYear().then(response => {
            let financialYearList = [{ value: '', label: 'Select financial year' }];
            if (response.data?.data?.length > 0) {
                response.data?.data?.forEach((category) => {
                    financialYearList.push({ value: category?.id, label: category?.financialYear });
                });
                setFinancialYearOptions(financialYearList);
                setLoading(false);
            }
        });

    }, []);

    



    // Handle last page deletion item
    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
        setLoading(false)
    }, [dataQuery.data?.data?.totalPages]);


    

    const columns = React.useMemo(() => [
        {
            accessorFn: (row) => row.financialYear,
            id: "financialYear",
            header: () => "Financial Year",
        },
        {
            accessorFn: (row) => row.month,
            id: "month",
            header: () => "Month",
        },
        {
            accessorFn: (row) => row?.company,
            id: "company",
            header: () => "Company",
        },
        {
            accessorFn: (row) => row?.saleLocation,
            id: "saleLocation",
            header: () => "Sale Location",
        },
        {
            accessorFn: (row) => row?.totalQuantitySold,
            id: "totalQuantitySold",
            header: () => "Total Qty Sold",
        },
        {
            accessorFn: (row) => row.calorificValue,
            id: "calorificValue",
            header: () => "Calorific Value",
        },
        {
            accessorFn: (row) => row.subsidyAmount,
            id: "subsidyAmount",
            header: () => "Subsidy Amount",
        },
        {
            accessorFn: (row) => row.consumerType,
            id: "consumerType",
            header: () => "Consumer Type",
        },
        {
            accessorFn: (row) => row.consumerName,
            id: "consumerName",
            header: () => "Consumer",
        },
        {
            accessorFn: (row) => row.districtName,
            id: "districtName",
            header: () => "District",
        },
        // {
        //     id: "actions",
        //     isAction: true,
        //     cell: (info) => {
        //         return (
        //             <div className="d-flex items-center gap-2 pointer">
        //             {permission.current.editModule ?
        //                 <div onClick={() => handleEyeIconClick(info.row.original)}>

        //                     {SvgIcons.eyeIcon}

        //                 </div>: ""}
        //             </div> 
        //         );
        //     },
        //     header: () => "Actions",
        //     enableSorting: false,
        // },
    ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    

    

    return (
        <React.Fragment>
            {loading ? <Loader isLoading={loading} /> :
                <>
                    <div className="d-flex flex-column pageContainer h-100 overflow-auto">
                        <div className="flex-grow-1 pageContent position-relative  overflow-auto">
                            <Card className="h-100 bg-white border-0 theme-card-cover">
                                <SearchForm monthOptions={monthOptions} financialYearOptions={financialYearOptions} companyOptions={companyOptions} filter={filter} setFilter={setFilter} />
                                <UsersTable
                                    columns={columns}
                                    dataQuery={dataQuery}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    sorting={sorting}
                                    setSorting={setSorting}
                                />
                            </Card>
                        </div>

                        
                    </div>
                </>
            }
        </React.Fragment>
    );
}
