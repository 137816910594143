export const hindiLang = {
   
    // HEADER
    "WELCOME": "स्वागत",
    "MENU": "मेन्यू",
    "NORTH EAST GAS SUBSIDY SCHEME PORTAL" : "पूर्वोत्तर गैस सब्सिडी योजना पोर्टल",
    "EMPOWERING COMMUNITIES WITH SUBSIDIZED GAS IN NORTH EAST INDIA": "पूर्वोत्तर भारत में सब्सिडीयुक्त गैस से समुदायों को सशक्त बनाना",
    "HOME": "घर",
    "ABOUT NE GAS SUBSIDY SCHEME": "पूर्वोत्तर गैस सब्सिडी योजना के बारे में",
    "SUBSIDIZED GAS SUPPLY INFORMATION": "सब्सिडी वाली गैस आपूर्ति की जानकारी",
    "NEWS & UPDATES": "समाचार और अपडेट",
    "DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA": "असम और त्रिपुरा को आवश्यक ऊर्जा उपलब्ध कराना",
    "LEARN MORE": "और अधिक जानें",
    "ABOUT NORTH EAST GAS SUBSIDY": "नॉर्थ ईस्ट गैस सब्सिडी के बारे में",
    "SUBMIT": "जमा करना",
    "LORM IPSUM": "मरीज की देखभाल करना, कोचिंग प्रक्रिया का पालन करना महत्वपूर्ण है, लेकिन मैं इसे उसी समय कर रहा हूं",
    "CONTACTUS": "संपर्क",
    "MISSION": "उद्देश्य",
    "VISION": "दृष्टि",
    "VALUES": "मान",
    "SUBSIDIZED GAS PROGRAM": "सब्सिडीयुक्त गैस कार्यक्रम",
    "OVERVIEW": "योजना अधिसूचना",
    "ELIGIBILITY AND BENEFITS": "योजना से लाभान्वित होने वाले प्रमुख क्षेत्र",
    "LANGUAGE": "भाषा",
    "NAME": "नाम",
    "EMAIL ADDRESS": "मेल पता",
    "SUBJECT": "विषय",
    "MESSAGE": "संदेश",
    "LOADING": "लोड हो रहा है",
    "HINDI": "हिंदी",
    "ENGLISH": "अंग्रेज़ी",
    "ASSAMESE": "असमिया",
    "ALL RIGHTS RESERVED" : "सर्वाधिकार सुरक्षित",
    "COPYRIGHTS": "कॉपीराइट",
    "LOGIN": "लॉगइन",
    "ABOUT_1": "वर्तमान सब्सिडी योजना को पेट्रोलियम और प्राकृतिक गैस मंत्रालय (एमओपीएनजी), भारत सरकार द्वारा पत्र संख्या एल.12015/13/10-जीपी दिनांक 15.11.2010 के माध्यम से 01.06.2010 से अधिसूचित किया गया था।",
    "ABOUT_2": "इस योजना के अंतर्गत, निर्दिष्ट उपभोक्ताओं को एक निश्चित मात्रात्मक सीमा तक गैस सब्सिडी दरों पर (अर्थात ओएनजीसी और ऑयल इंडिया के नामांकित क्षेत्रों से गैस पर लागू गैस मूल्य का 60%) आपूर्ति की जाती है।",
    "ABOUT_3": "ओएनजीसी और ऑयल इंडिया, गेल और एजीसीएल को सब्सिडी वाली गैस की आपूर्ति करते हैं तथा मासिक आधार पर पेट्रोलियम एवं प्राकृतिक गैस मंत्रालय से सब्सिडी राशि का दावा करते हैं।",
    "ABOUT_4": "गेल, एजीसीएल और टीएनजीसी चाय बागानों जैसे अंतिम उपभोक्ताओं को सब्सिडी वाली गैस की आपूर्ति करते हैं।",
    "ABOUT_5": "यह पूर्वोत्तर क्षेत्र में अपने व्यापक पाइपलाइन नेटवर्क के माध्यम से घरेलू पीएनजी उपभोक्ताओं, सीएनजी स्टेशनों, बिजली संयंत्रों और अन्य को बिजली आपूर्ति करता है।",
    "NORTH EAST GAS SUBSIDY SCHEME": "पूर्वोत्तर गैस सब्सिडी योजना",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 1": "(MoP&NG), भारत सरकार द्वारा पत्र संख्या L.I2015/13/10-GP दिनांक 15.11.2010",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 2": "लॉगइन",
    "MAJOR 1": "सीजीडी के माध्यम से घरेलू पीएनजी उपभोक्ता",
    "MAJOR 2": "सीएनजी स्टेशनों के माध्यम से सीएनजी उपभोक्ता",
    "MAJOR 3": "चाय बागान",
    "MAJOR 4": "बिजली क्षेत्र",
    "MAJOR 5": "उर्वरक क्षेत्र",
    "CONTACTUS DESCRIPTION": "हमारी टीम के साथ संपर्क में रहें",
    "NORTH EAST GAS DESCRIPTION" : "पूर्वोत्तर गैस सब्सिडी योजना – लाभ विवरण",
    "SLIDE 1": "सब्सिडीयुक्त गैस के माध्यम से पूर्वोत्तर भारत में समुदायों को सशक्त बनाना",
    "SLIDE 2": "पूर्वोत्तर राज्यों को स्वच्छ ऊर्जा से सशक्त बनाना",
    "SLIDE 3": "पूर्वोत्तर के प्रति भारत सरकार की प्रतिबद्धता",
    "ONGC": "ओएनजीसी",
    "OIL": "ऑयल इंडिया लिमिटेड",
    "ONGC ADDRESS": "आई/सी कॉर्पोरेट कमर्शियल ओएनजीसी लिमिटेड, दीन दयाल ऊर्जा भवन, तीसरी मंजिल, 5 नेल्सन मंडेला मार्ग, वसंत कुंज, नई दिल्ली - 110070",
    "OIL ADDRESS": "सीजीएम (एफएंडए) ऑयल इंडिया लिमिटेड, प्लॉट नंबर 19, फिल्म सिटी के पास, सेक्टर 16, नोएडा - 201301",

    "GAS SUBSIDY BENEFIT BY CONSUMER CATEGORY": "उपभोक्ता श्रेणी के अनुसार गैस सब्सिडी लाभ (करोड़ रुपये में)",
    "GAS SUBSIDY BENEFIT BY STATE": "राज्यवार गैस सब्सिडी लाभ (रु.)",
    
}