import { Navigate } from "react-router-dom";
import { useContext } from "react";

import { AuthenticationContext } from "../contexts/authentication.context";

const PrivateRoute = ({ element }) => {
    const { isAuthenticated } = useContext(AuthenticationContext);

    if (isAuthenticated) {
        return element;
    } else {
        return <Navigate to='/home' replace />;
    }
};

export default PrivateRoute;