import * as React from 'react';
import { Card } from "react-bootstrap";
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { BarChart } from '@mui/x-charts/BarChart';

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

const BarChartModel = ({ chartData, heading }) => {
  return (
    
      <Card.Body>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="chart-cover">
            <BarChart
              width={500}
              height={300}
              // series={[{ data: uData, label: 'uv', type: 'bar' }]}
              // xAxis={[{ scaleType: 'band', data: xLabels }]}
              series={[{ data: chartData.graphValues || [],  type: 'bar', barWidth: 30, }]}
              xAxis={[{ 
                scaleType: 'band', 
                data: chartData.valueNames || [], 
                barGapRatio: 0.4, // Adjust the gap between bars
                categoryGapRatio: 0.7 
              }]}
              colors={['#A12B31']}
              borderRadius={16}
              barLabel="value"
              
            >
              {/* <BarPlot /> */}
            </BarChart>
          </div>
        </div>
      </Card.Body>
    

  );
}
export default BarChartModel;


