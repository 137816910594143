import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import FormInput from "../../components/FormInput";
import ReactSelect from "../../components/ReactSelect";
import { HiMiniUsers } from 'react-icons/hi2';
import { handleGetConsumerCategories } from '../../services/consumercategory.service';
import { handleGetDistricts } from '../../services/district.service';
import { handleGetStates } from '../../services/state.service';
import SvgIcons from "../../components/SVGIcons"
import { handleGetFinancialYears } from '../../services/financialyear.service';
import { handleGetCompany } from "../../services/claim.service";

const SearchForm = ({ filter, setFilter }) => {
    const [districtOptions, setDistrictOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        // Fetch consumer categories
        handleGetConsumerCategories().then(response => {
            setCategoryOptions(response.data.data.map(category => ({
                value: category.id,
                label: category.categoryName,
            })));
        });

        // Fetch districts
        handleGetDistricts().then(response => {
            setDistrictOptions(response.data.data.map(district => ({
                value: district.id,
                label: district.districtName,
            })));
        });

        // Fetch states
        handleGetStates().then(response => {
            setStateOptions(response.data.data.map(state => ({
                value: state.id,
                label: state.stateName,
            })));
        });
        handleGetFinancialYears().then(response => {
            setFinancialYearOptions(response.data.data.map(fy => ({
                value: fy.id,
                label: fy.financialYear
            })));
        });
        handleGetCompany().then(response => {
            let companies = [];
            response.data.data.map(company => {
                if (company.title === 'ONGC' || company.title === 'OIL') {
                    companies.push({
                        value: company.id,
                        label: company.title,
                    });
                }
            })
            setCompanyOptions(companies);
        });
    }, []);

    const customerLevelOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Accepted', label: 'Accepted' },
        { value: 'Rejected', label: 'Rejected' },
    ];

    const vendorTypeOptions = [
        { value: 'Domestic', label: 'Domestic' },
        { value: 'Non Domestic', label: 'Non Domestic' },
    ];


    return (
        <div className="theme-card-header px-1 header-search">
            <Stack
                gap={3}
                className="flex-wrap px-3 pt-3 align-items-start "
            >

                <h5 className="mb-0 position-relative fw-semibold fs-16">
                    <div className="align-items-center bg-black d-inline-flex custom-height-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box custom-width-60 z-1">
                        <span className='page-header-icon'>{SvgIcons.filedetails}</span>
                    </div>
                    Vendor Subsidy Data File Details
                </h5>
                <div className='w-100'>
                    <Row className="g-3">
                        <Col md={2} className="d-flex">
                            <div className="custom-width-250 flex-grow-1 flex-md-grow-0">
                                <FormInput
                                    id="search"
                                    key={"search"}
                                    name="search"
                                    placeholder="Search"
                                    type="text"
                                    onChange={(event) => {
                                        if (event.target.value === "") {
                                            setFilter({
                                                ...filter,
                                                search: undefined,
                                            });
                                            return;
                                        }
                                        setFilter({
                                            ...filter,
                                            search: event.target.value,
                                        });
                                    }}
                                    value={filter.search}
                                />
                            </div>
                        </Col>
                        <Col md={10} className="d-flex justify-content-end">
                            <Row className="justify-content-end gx-2">
                            <Col className="col-md-auto col-12 mb-3 mb-md-0">
                            <ReactSelect
                                        id="vendorType"
                                        key="vendorType"
                                        name="vendorType"
                                        label=""
                                        placeholder="All Vendor Type"
                                        value={filter.vendorType}
                                        options={vendorTypeOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                vendorType: option ? option.target.value : ''
                                            });
                                        }}
                                    />
                                </Col>

                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        label={""}
                                        class="form-select "
                                        placeholder="All Gas producer"
                                        id="gasProducer"
                                        options={companyOptions}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                gasProducer: e.target.value,
                                            });
                                        }}
                                        value={filter.gasProducer}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        label={""}
                                        id="financialYear"
                                        key="financialYear"
                                        name="financialYear"
                                        class="form-select "
                                        placeholder="All Financial Years"
                                        aria-label="Floating label select example"
                                        options={financialYearOptions}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                financialYearId: e.target.value,
                                                financialYear: e.target.value ? e.target.value : ''
                                            });
                                        }}
                                        value={filter.financialYear}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        label={""}
                                        id="state"
                                        key="state"
                                        name="state"
                                        class="form-select "
                                        placeholder="All State"
                                        aria-label="Floating label select example"
                                        options={stateOptions}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                saleLocationId: e.target.value,
                                                state: e.target.value ? e.target.value : ''
                                            });
                                        }}
                                        value={filter.state}
                                    />
                                </Col>
                                <Col className="col-md-auto col-12 mb-3 mb-md-0">
                                    <ReactSelect
                                        id="status"
                                        key="status"
                                        name="status"
                                        label=""
                                        placeholder="All Status"
                                        value={filter.status}
                                        options={customerLevelOptions}
                                        onChange={(option) => {
                                            setFilter({
                                                ...filter,
                                                status: option ? option.target.value : '',
                                                // status: option ? option.target.value : '',
                                            });
                                        }}
                                    />
                                </Col>

                            </Row>
                        </Col>


                    </Row>

                </div>


            </Stack>
        </div>
    );
};

export default SearchForm;
