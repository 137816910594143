import instance from "../utils/instance";

export const handleGetGoals = async (params) => {
    return await instance.get('/v1/goals', {
        params
    });
}

export const handleDeleteGoal = async (id) => {
    return await instance.delete(`/v1/goals/${id}`);
}
