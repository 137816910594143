import * as Yup from "yup";

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validationSchema = Yup.object({
    consumerType: Yup.string().required("Consumer type is required."),
    customerLevel: Yup.string().required("Customer level is required."),
    consumerName: Yup.string().required("Consumer name is required."),
    consumerKey: Yup.string().required("Consumer key is required."),
    address: Yup.string().max(255,'Maximum limit is 255'),
    email: Yup.string().matches(emailRegExp, "Email must be a valid email"),
    districtId: Yup.string().required("Please Select District"),
    stateId: Yup.string().required("Please Select State"),
    cityTown: Yup.string().optional().max(255,'Maximum limit is 255'),
    pinCode: Yup.string().optional().max(10,'Maximum limit is 10'),
    gstRegistrationNo: Yup.string().optional().max(50,'Maximum limit is 50'),
    // consumerCategoryId: Yup.number().required("Consumer category is required."),
    udyogAadharNumber: Yup.string().optional().max(50,'Maximum limit is 50'),
    status: Yup.boolean().required("Status is required."),
    latitude: Yup.string().optional().max(50,'Maximum limit is 50'),
    longitude: Yup.string().optional().max(50,'Maximum limit is 50'),
    pan: Yup.string().optional().max(10,'Maximum limit is 10').min(10,'Minimum limit is 10'),
    supplyThroughId: Yup.string().required("Please Select Supply Through"),
    aadharNumber: Yup.string().optional().max(12,'Maximum limit is 12').min(12,'Minimum limit is 12')
});

export { validationSchema };
