import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { I18nextProvider, useTranslation } from "react-i18next"
import { getLocalStorage, setLocalStorage } from '../../utils/storage';


export default function Footer({ isActiveSidebar, toggleSidebarButton }) {

    const {i18n} = useTranslation()

    const {t} = useTranslation()


    return (
        <>
            <section className='bg-new-black'>
                <Container>
                    <Row>
                        <div className='footer-one'>{t('COPYRIGHTS')} © 2024. {t('ALL RIGHTS RESERVED')}</div>
                    </Row>
                </Container>
            </section>
        </>

    )
}
