import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Modal, Button } from "react-bootstrap";
import Header from "./Header";
import SearchForm from "./SearchForm";
import TemplateManagementTable from "./TemplateManagementTable";
import TemplateManagementModal from "./TemplateManagementModal";
import { handleGetTemplates, handleGetTemplateById, handleDownloadTemplate } from "../../services/templatemanagement.service";
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import SvgIcons from "../../components/SVGIcons"
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";
export default function TemplateManagementList() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });
    const [isListEmpty, setEmptyList] = useState(false);
    const [modal, setModal] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
    });

    const permission = useRef({ viewTemplate: false, editTemplate: false });

    useEffect(() => {
        isAdminUser().then(response => {
            if (response) {
                permission.current.viewTemplate = true;
                permission.current.editTemplate = true;
            } else {
                getModulePermissions("Data Template").then(response => {
                    if (response.includes("TEMPLATE_VIEW")) {
                        permission.current.viewTemplate = true;
                    }
                    if (response.includes("TEMPLATE_UPDATE")) {
                        permission.current.editTemplate = true;
                    }
                }).catch(error => {
                    console.error("Error fetching permissions:", error);
                });
            }
        }).catch(error => {
            console.error("Error get during to fetch User Type", error);
        })

    }, []);


    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const toggle = () => setModal(!modal);

    const editTemplate = async (id) => {
        navigate(`/template-management/edit/${id}`);
    };

    const viewTemplate = async (id) => {
        try {
            navigate(`/template-management/view/${id}`);
            const response = await handleGetTemplateById(id);
            // Handle the response to view the template
            // For example, you can open the template in a new tab or modal
            console.log(response);
            // Assuming the response contains a URL to view the template
            if (response?.data?.url) {
                window.open(response.data.url, "_blank");
            } else {
                toast.error("Unable to view the template");
            }
        } catch (error) {
            toast.error(error.response.data.detail);
        }
    };

    const userDetailQuery = useQuery({
        queryKey: ["userDetail", selectedUserId],
        queryFn: () => handleGetTemplateById(selectedUserId),
        enabled: !!selectedUserId, // Only run this query if selectedUserId is set
    });

    const dataQuery = useQuery({
        queryKey: ["templates", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return handleGetTemplates({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return handleGetTemplates({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });

    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
        setEmptyList(dataQuery.data?.data?.content?.length === 0);
        console.log("Content Record:::", dataQuery.data?.data?.content?.length)
    }, [dataQuery.data?.data?.totalPages, dataQuery.data?.data?.content?.length]);

    const columns = React.useMemo(
        () => [
            {
                accessorFn: (row) => row.title,
                id: "templateName",
                header: () => "Name",
            },
            {
                accessorFn: (row) => format(new Date(row.createdAt), 'dd-MM-yyyy'),
                id: "createdAt",
                header: () => "Upload Date",
            },
            {
                id: "actions",
                isAction: true,
                cell: (info) => {
                    return (
                        <div className="d-flex items-center gap-2 justify-content-center">
                            {permission.current.editTemplate ? <div
                                onClick={() => {
                                    editTemplate(info.row.original.id);
                                }}
                            >
                                <span className=''>{SvgIcons.editIcon}</span>
                            </div> : ''}

                            {permission.current.viewTemplate ? <div
                                onClick={() => handleEyeIconClick(info.row.original)}
                            >
                                <span className=''>{SvgIcons.eyeIcon}</span>
                            </div> : ''}
                        </div>
                    );
                },
                header: () => <div className="d-flex justify-content-center">Actions</div>,
                enableSorting: false,
            },
        ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    const handleEyeIconClick = (user) => {
        setSelectedUserId(user.id);
        setShowModal(true);
    };
    const handleFileClick = (e, fileKey) => {
        handleDownloadTemplate({ fileKey }).then(response => {
            //toast.success(response.data.data.message);
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            const file = String(fileKey.split("/").pop());
            const aTag = document.createElement("a");
            aTag.href = blobUrl;
            aTag.setAttribute("download", file);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
            //navigate("/templates");
        }).catch((error) => {
            if (error.response.data.fieldErrors) {
                toast.error(error.response.data.fieldErrors[0].message);
            } else {
                toast.error(error.response.data.detail);
            }
        });
    };

    return (
        <React.Fragment>
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto" >
                <Header toggle={toggle} isListEmpty={isListEmpty} />
                <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <SearchForm filter={filter} setFilter={setFilter} />
                        <TemplateManagementTable
                            columns={columns}
                            dataQuery={dataQuery}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </Card>

                    <Modal centered show={showModal} onHide={() => setShowModal(false)} className="templateDetailModal">
                        <Modal.Header closeButton>
                            <Modal.Title>Template Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {userDetailQuery.isLoading && <p>Loading...</p>}
                            {userDetailQuery.isError && <p>Error loading user details</p>}
                            {userDetailQuery.data && (
                                <div className="modalInner">
                                    <div className="orderCaret">
                                        <div className="row">
                                            <div className="col"><p className="d-flex align-items-start"><strong>Name</strong> {userDetailQuery.data.data.data.title}</p></div>
                                            <div className="col"><p className="d-flex align-items-start"><strong>Version</strong> 1.0</p></div>
                                            <div className="col"><p className="d-flex align-items-start"><strong>Upload Date</strong> {format(new Date(userDetailQuery.data.data.data.updatedAt), 'dd-MM-yyyy')}</p></div>
                                        </div>
                                    </div>

                                    <div className="orderCaret">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="fileArea">

                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>ONGC & Oil India</strong></p>
                                                        {userDetailQuery.data.data.data.ongcOilIndiaTemplate ?
                                                            <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.ongcOilIndiaTemplate)}>
                                                                <span className="fileIco">
                                                                    {SvgIcons.downloadIocn}
                                                                </span> Download
                                                            </a> : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fileArea">
                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>Vendor Domestic</strong></p>
                                                        {userDetailQuery.data.data.data.vendorDomesticTemplate ?
                                                            <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.vendorDomesticTemplate)}>
                                                                <span className="fileIco">
                                                                    {SvgIcons.downloadIocn}
                                                                </span>Download
                                                            </a> : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fileArea">

                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>Vendor Non-Domestic</strong></p>
                                                        {userDetailQuery.data.data.data.vendorNonDomesticTemplate ?
                                                            <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.vendorNonDomesticTemplate)}>
                                                                <span className="fileIco">
                                                                    {SvgIcons.downloadIocn}
                                                                </span>
                                                                Download</a>
                                                            : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fileArea">
                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>Annexure A for ONGC & Oil India</strong></p>
                                                        {userDetailQuery.data.data.data.annexureA ? <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.annexureA)}>
                                                            <span className="fileIco">
                                                                {SvgIcons.downloadIocn}
                                                            </span>
                                                            Download</a> : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fileArea">

                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>Annexure B for ONGC & Oil India</strong></p>
                                                        {userDetailQuery.data.data.data.annexureB ?
                                                            <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.annexureB)}>
                                                                <span className="fileIco">
                                                                    {SvgIcons.downloadIocn}
                                                                </span>Download</a> : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fileArea">

                                                    <div className="col">
                                                        <p className="d-flex align-items-start"><strong>Consumer Import Template</strong></p>
                                                        {userDetailQuery.data.data.data.consumerImportTemplate ?
                                                            <a href="#" onClick={(e) => handleFileClick(e, userDetailQuery.data.data.data.consumerImportTemplate)}>
                                                                <span className="fileIco">
                                                                    {SvgIcons.downloadIocn}
                                                                </span>Download</a> : <strong>N/A</strong>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    {/* <p><strong>Updated At:</strong> {moment(userDetailQuery.data.data.data.createdAt).format("D/M/YYYY hh:mm A")}</p>
                                    <p><strong>Uploaded By:</strong> {userDetailQuery.data.data.data.uploadedBy}</p>
                                    <p><strong>Uploaded File:</strong> {parseFilePaths(userDetailQuery.data.data.data.filePathsList)}</p> */}
                                    {/* Add other user details as needed */}
                                </div>
                            )}
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)} className="footerBtnSubmit fs-14 bg-info text-white text-decoration-none rounded-2 p-2 text-center">
                                Download All Files
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
                </div>
            </div>
            <TemplateManagementModal modal={modal} toggle={toggle} />
        </React.Fragment>
    );
}
