import instance from "../utils/instance";

export const handleGetUsers = async (params) => {
    return await instance.get('/v1/ne-subsidy-claim-statements', {
        params
    });
}

export const handleGetUser = async (id) => {
    return await instance.get(`/v1/users/${id}`);
}

export const handleUpdateClaim = async (id, data) => {
    return await instance.put(`/v1/ne-subsidy-claim-statements`, data);
}

export const handleDeleteUser = async (id) => {
    return await instance.delete(`/v1/ne-subsidy-claim-statements/${id}`);
}

export const handleStatusChangeState = async (id, status) => {
    return await instance.put(`/v1/admin/users/${id}/status?active=${status}`, {});
}

export const handleGetClaimById = async (id) => {
    return await instance.get(`/v1/ne-subsidy-claim-statements/${id}`);
}

export const downloadFile = async (file) =>{
    let data = {
        fileKey: file
    };
    return await instance.post(`/v1/ne-subsidy-claim-statements/files`,data);
}

export const handleAddClaim = async (data) => {
    return await instance.post('/v1/ne-subsidy-claim-statements', data);
}

export const handleGetCompany = async (id) => {
    return await instance.get(`/v1/companies`);
}

export const handleGetFinancialYear = async () => {
    return await instance.get(`/v1/financial-years/list`);
}

export const handleUserResetPassword = async (id)=>{
    return await instance.get(`/v1/admin/users/reset-password/${id}`)
}

