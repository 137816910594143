import instance from "../utils/instance";

export const handleGetNonDomesticConsumers = async (params) => {
    return await instance.get('/v1/consumers', {
        params
    });
}

export const handleDeleteNonDomesticConsumer = async (id) => {
    return await instance.delete(`/v1/consumer/${id}`);
}

export const handleGetNonDomesticConsumerById = async (id) => {
    return await instance.get(`/v1/consumer/${id}`);
}

export const handleAddNonDomesticConsumer = async (data) => {
    return await instance.post('/v1/consumer', data);
}

export const handleEditNonDomesticConsumer = async (id, data) => {
    data.id = id;
    return await instance.put(`/v1/consumer`, data);
}

export const handleImportConsumerData = async(formData)=>{
    return await instance.post('/v1/consumers/import', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
