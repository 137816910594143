import React, { useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import FormInput from "../../components/FormInput";
import { HiMiniUsers } from 'react-icons/hi2';
import ReactSelect from '../../components/ReactSelect';
import DateRangePicker from '../../components/DateRangePicker';
import SvgIcons from "../../components/SVGIcons"
import {
    handleGetDistrictById,
} from "../../services/website.service";
const SearchForm = ({ financialYearOptions, companyOptions, filter, setFilter, monthOptions, stateOptions }) => {

    const [districtOptions, setDistrictOptions] = useState([]);

    const handleStateChange = (stateId) => {
        //setFieldValue("districtId", '')
       
        if (stateId != '') {
            setDistrictOptions([]);
            handleGetDistrictById(stateId).then(response => {
                let financialYearList = [{ value: '', label: 'Select district' }];
                if (response.data?.data?.length > 0) {
                    response.data?.data?.forEach((category) => {
                        financialYearList.push({ value: category?.id, label: category?.districtName });
                    });
                    setDistrictOptions(financialYearList);
                    // setLoading(false);
                }
            });
        } else {
            setFilter({
                ...filter,
                saleLocationId: '',
                districtId: '',
            });
            setDistrictOptions([]);
        }

    };

    return (
        <div className="theme-card-header px-1 ">
            <Stack
                gap={3}
                className="flex-wrap  pt-3  align-items-start"
            >

                <div className="w-100">
                    <Row className="g-3 mb-4 react-dropdownheight-40">

                        <Col>
                            <Row className="align-items-start g-2 justify-content-center mb-4 d-flex custom-new-wrap">

                                <Col sm md="auto" className="d-flex custom-flex" >

                                    <div className=" flex-grow-1 flex-md-grow-0 min-width-155 w-100">

                                        <ReactSelect
                                            label={""}
                                            id="supplyId"
                                            key="supplyId"
                                            name="supplyId"
                                            class="form-select "
                                            placeholder="All Company"
                                            aria-label="Floating label select example"
                                            options={companyOptions}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    supplyId: e.target.value,
                                                    company: e.target.value ? e.target.value : ''
                                                });
                                            }}
                                            value={filter.supplyId}
                                        />

                                    </div>
                                </Col>


                                <Col sm md="auto" className="d-flex custom-flex" >
                                    <div className=" flex-grow-1 flex-md-grow-0 min-width-155 w-100">
                                        <ReactSelect
                                            label={""}
                                            id="financialYear"
                                            key="financialYear"
                                            name="financialYear"
                                            class="form-select "
                                            placeholder="All Financial Years"
                                            aria-label="Floating label select example"
                                            options={financialYearOptions}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    financialYearId: e.target.value,
                                                    financialYear: e.target.value ? e.target.value : ''
                                                });
                                            }}
                                            value={filter.financialYear}
                                        />
                                    </div>
                                </Col>
                                <Col sm md="auto" className="d-flex custom-flex " >
                                    <div className=" flex-grow-1 flex-md-grow-0  min-width-155 w-100">
                                        <ReactSelect
                                            label={""}
                                            id="month"
                                            key="month"
                                            name="month"
                                            class="form-select "
                                            placeholder="All Months"
                                            aria-label="Floating label select example"
                                            options={monthOptions}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    month: e.target.value,
                                                });
                                            }}
                                            value={filter.month}
                                        />
                                    </div>
                                </Col>
                                <Col sm md="auto" className="d-flex custom-flex " >
                                    <div className=" flex-grow-1 flex-md-grow-0  min-width-155 w-100">
                                        <ReactSelect
                                            label={""}
                                            id="stateId"
                                            key="stateId"
                                            name="stateId"
                                            class="form-select "
                                            placeholder="State"
                                            aria-label="Floating label select example"
                                            options={stateOptions}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    saleLocationId: e.target.value,
                                                    districtId : ''
                                                });
                                                handleStateChange(e.target.value);
                                                
                                            }}
                                            // onChange={(e) => handleUploadFile(e, setFieldValue)}

                                            value={filter.saleLocationId}
                                        />
                                    </div>
                                </Col>
                                {/* <Col sm md="auto" className="d-flex custom-flex " >
                                    <div className=" flex-grow-1 flex-md-grow-0  min-width-155 w-100">
                                        
                                        <ReactSelect
                                            label={""}
                                            id="districtId"
                                            key="districtId"
                                            name="districtId"
                                            class="form-select "
                                            placeholder="District"
                                            aria-label="Floating label select example"
                                            options={districtOptions}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    districtId: e.target.value,
                                                });
                                            }}

                                            value={filter.districtId}
                                        />
                                    </div>
                                </Col> */}
                                {/* <button type="submit" className="btn btn-primary custom-width-85">

                                    Search

                                </button> */}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Stack>
        </div>
    );
};

export default SearchForm;
