import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap"
import OBJECTS from "../../assets/images/OBJECTS.png"
import Mission from "../../assets/images/Mission.svg"
import spider from "../../assets/images/spider.svg"
import daimond from "../../assets/images/daimond.svg"
import pipes from "../../assets/images/pipes.png"
import notificationPdf from "../../assets/images/notification-pdf.pdf"
import search from "../../assets/images/search.svg"
import poll from "../../assets/images/poll.svg"
import ImageSlider from "./ImageSlider"
import { Form as FormikForm, Formik } from 'formik'
import toast from 'react-hot-toast'
import FormInput from '../../components/FormInput'
import ReactSelect from "react-select"
import * as Yup from "yup";

import slid1 from "../../assets/images/slid1.jpg"
import slid2 from "../../assets/images/slid2.jpg"
import slid3 from "../../assets/images/slid3.jpg"
import slid4 from "../../assets/images/slid4.JPG"
import slid5 from "../../assets/images/slid5.JPG"
import slid6 from "../../assets/images/slid6.jpg"

import {
  handleGetCompany,
  handleGetRecentNews,
  getListingData,
  handleGetStates,
  handleGetFinancialYear,
  getSubsidyGraphListingData,
  submitContactUs,
} from "../../services/website.service";
import SubsidyGasProgram from "../subsidy-gas-program";
import SubsidyGasProgramGraph from "../subsidy-gas-program-graph"
import { Link } from "react-router-dom"
// import { validationSchema } from '../../validations/contactus.validation';
import Captcha from "../../components/Captcha";
import { I18nextProvider, useTranslation } from "react-i18next"
import { getLocalStorage } from '../../utils/storage';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    margin: 20,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default function Home() {
  const [filter, setFilter] = useState({

  })
  const [captcha, setCaptcha] = useState("");
  const reCaptchaRef = useRef(true);
  const [recentNewsList, setRecentNewsList] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState('xxx'); // recaptchaToken
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialContactData, setInitialContactData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  })


  const { i18n } = useTranslation()

  const { t } = useTranslation()

  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  

  useEffect(() => {


    handleGetRecentNews().then((response) => {
      let recentNewsListArr = [];
      if (response.data?.data?.length > 0) {
        response.data?.data?.forEach((category) => {
          recentNewsListArr.push({
            slug: category?.slug,
            title: category?.title,
            shortDescription: category?.shortDescription,
            imageUrl: category?.imageUrl
          });
        });
        setRecentNewsList(recentNewsListArr);
      }
    });

    //setLoading(false);
  }, [i18n?.language]);

  const onSubmit = async (values) => {
    if (captcha === "") {
      toast.error("Please enter the captcha");
      return;
    }
    values.recaptchaToken = captcha != '' ? captcha : ''
    console.log('onSubmit', values)
    await submitContactUs(values).then(response => {
      toast.success(response.data.message);
    }).catch((error) => {
      if (error.response.data.fieldErrors) {
        toast.error(error.response.data.fieldErrors[0].message);
      } else {
        toast.error(error.response.data.detail);
      }
    });
    reCaptchaRef.current.reset();
    setCaptcha('')
    values.name = '';
    values.email = '';
    values.subject = '';
    values.message = '';

  };

  // Function to translate text based on the user's language preference
  function translateText(hindiText, englishText, assameseText) {
    console.log('in aya')
    const userLanguage = localStorage.getItem('langKey');
    console.log('sudhir 108', userLanguage)
    return userLanguage == '"en"' ? englishText : userLanguage == '"as"' ? assameseText : hindiText;
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailRegExp, translateText("ईमेल वैध ईमेल होना चाहिए।", "Email must be a valid email.", "ইমেইল এটা বৈধ ইমেইল হ’ব লাগিব।"))
      .required(translateText("ईमेल आवश्यक है।", "Email is required.", "ইমেইলৰ প্ৰয়োজন"))
      .max(100, translateText("अधिकतम सीमा 100 है।", "Maximum limit is 100", "সৰ্বোচ্চ সীমা ১০০")),
    name: Yup.string()
      .max(100, translateText("अधिकतम सीमा 100 है।", "Maximum limit is 100","সৰ্বোচ্চ সীমা ১০০"))
      .required(translateText("नाम आवश्यक है।", "Name is required.", "নাম দৰকাৰ")),
    subject: Yup.string()
      .max(100, translateText("अधिकतम सीमा 100 है।", "Maximum limit is 100", "সৰ্বোচ্চ সীমা ১০০"))
      .required(translateText("विषय आवश्यक है।", "Subject is required.", "বিষয়ৰ প্ৰয়োজন")),
    message: Yup.string()
      .max(100, translateText("अधिकतम सीमा 100 है।", "Maximum limit is 100", "সৰ্বোচ্চ সীমা ১০০"))
      .required(translateText("संदेश आवश्यक है।", "Message is required.", "বাৰ্তা প্ৰেৰণৰ প্ৰয়োজন")),
  });

  return (
    <>
      <section className="home-banner">


        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={false}
          infinite={true}
          partialVisible={false}
          dotListClass="custom-dot-list-style"
        >
          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid1})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 1")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>

          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid4})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 1")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>

          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid2})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 2")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>

          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid5})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 2")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>

          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid3})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 3")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>
          <div className="home-banner-bg" style={{ backgroundImage: `url(${slid6})` }}>
            <div className="max-w-776 text-center">
              <div className="fs-40 fw-bolder text-white text-center title-line">
                {t("SLIDE 3")}
              </div>
              <div className="text-white fs-24 text-center">
                {/* {t("DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA")} */}
              </div>
              {/* <Button className="btn btn-link bg-white text-decoration-none custom-min-width-130 h-44 mt-4">
                {t("LEARN MORE")}</Button> */}
            </div>
          </div>

        </Carousel>


      </section>
      <section id="about" className="bg-white py-5">
        <Container>
          <Row>
            <Col className="text-center">
              <h3 className="mb-1 fs-40 text-primary fw-semibold">{t("ABOUT NORTH EAST GAS SUBSIDY")}</h3>
              <p className="mb-5">{t("EMPOWERING COMMUNITIES WITH SUBSIDIZED GAS IN NORTH EAST INDIA")}</p>
            </Col>
          
          </Row>
          <Row>
            <Col md={4}>
              <div className="india-image">
                <Image
                  className="img-fluid"
                  src={OBJECTS}
                  alt={`indiamap`}

                />
              </div>
            </Col>
            <Col md={8}>
              <div className="mission-cover">
                <div className="mission-repeat d-flex">
                  <div className="mission-left me-3">
                    <Image
                      className="img-fluid"
                      src={Mission}
                      alt={`Mission`}
                      width={72}
                      height={72}
                    />
                  </div>
                  <div className="mission-right">
                    {/* <div className="fs-24 fw-bolder text-primary">{t("MISSION")}</div> */}
                    <p className="mb-0">{t("ABOUT_1")}
                    </p>
                  </div>
                </div>

                <div className="mission-repeat d-flex">
                  <div className="mission-left me-3">
                    <Image
                      className="img-fluid"
                      src={spider}
                      alt={`Web`}
                    />
                  </div>
                  <div className="mission-right">
                    {/* <div className="fs-24 fw-bolder text-primary">{t("VISION")}</div> */}
                    <p className="mb-0">{t("ABOUT_2")}
                    </p>
                  </div>
                </div>

                <div className="mission-repeat d-flex">
                  <div className="mission-left me-3">
                    <Image
                      className="img-fluid"
                      src={daimond}
                      alt={`Mission`}

                    />
                  </div>
                  <div className="mission-right">
                    {/* <div className="fs-24 fw-bolder text-primary">{t("VALUES")}</div> */}
                    <p className="mb-0">{t("ABOUT_3")}</p>
                    <p className="mb-0">{t("ABOUT_4")}</p>
                    <p className="mb-0">{t("ABOUT_5")}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="subsidized-gas-program" className="section-3rd">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <h3 className="mb-1 fs-40 text-white fw-semibold">{t("NORTH EAST GAS SUBSIDY SCHEME")}</h3>
              <p className="mb-4 text-white">{t("NORTH EAST GAS SUBSIDY SCHEME")}</p>
              <div className="subsidized_program_cover mb-3">
                <div className="mb-2">
                  <Image
                    className="img-fluid me-3 text-center"
                    src={search}
                    alt={`indiamap`}

                  />
                  <span className="fs-20 fw-bolder mb-3">{t("OVERVIEW")}</span>
                </div>
                <p>
                  <a href={notificationPdf} download={true} >
                    {t("NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 1")}
                  </a>
                </p>
              </div>
              <div className="subsidized_program_cover">
                <div className="mb-2 rounded-5">
                  <Image
                    className="img-fluid me-3"
                    src={poll}
                    alt={`indiamap`}
                  />
                  <span className="fs-20 fw-bolder mb-3">{t("ELIGIBILITY AND BENEFITS")}</span>
                  <br></br>

                </div>
                <ul>
                  <li>
                    {t("MAJOR 1")}
                  </li>
                  <li>
                    {t("MAJOR 2")}
                  </li>
                  <li>
                    {t("MAJOR 3")}
                  </li>
                  <li>
                    {t("MAJOR 4")}
                  </li>
                  <li>
                    {t("MAJOR 5")}
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} className="text-center ">
              <Image
                className="img-fluid rounded-5"
                src={pipes}
                alt={`indiamap`}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-4th bg-white py-40">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={12}>
              <h3 className="mb-1 fs-40 text-primary fw-semibold text-center">{t("SUBSIDIZED GAS PROGRAM")}</h3>
              <p className="mb-4 text-center">{t("NORTH EAST GAS DESCRIPTION")}</p>

              <div className="table-responsive custom-table home-table">
                {/* Gas subsidy Program */}
                {/* <SubsidyGasProgram /> */}
                <SubsidyGasProgramGraph />

                {/* <div className="text-center">
                  <Link to="/subsidized-gas-program" variant="outline-light" className="mt-4 h-44">View Full Report</Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="news-and-update" className="section-5th  py-40">
        <Container>
          <Row>
            <Col>
              <h2 className="text-white mb-5 fw-semibold fs-40">{t("NEWS & UPDATES")}</h2>
              <ImageSlider newsData={recentNewsList} />
            </Col>
          </Row>
        </Container>

      </section>


      {/* <section id="contact-us" className="section-contact  py-40">
        <Container>
          <Row>
            <Col>
              <h3 className="mb-1 fs-40 text-primary fw-semibold text-center">{t("CONTACTUS")}</h3>
              <p className="mb-4 text-center">{t("CONTACTUS DESCRIPTION")}</p>
              <Row>
                <Col>
                  <div class="">

                    <h3 className="white fw-bolder mt-4">{t("CONTACTUS")}</h3> 


                   

                      <div className="d-flex "> 
                        <div className="col-6 pe-5 mb-5">
                            <h5 className="white uline">{t("ONGC")}</h5>
                            <div>
                              {t("ONGC ADDRESS")}
                            </div>
                        </div>
                      
                      <div className="col-md-6  mb-5">
                        <div><h5 className="white uline">{t("OIL")}</h5>
                        <div>
                          {t("OIL ADDRESS")}
                        </div>
                        </div>
                      </div>
                      </div>
                      




                      



                   
                  </div>


                </Col>
                {/* <Col>
                  <div className="contact-form-cover">
                    <Formik
                      initialValues={initialContactData}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        setFieldValue
                      }) => (
                        <>
                          <FormikForm onSubmit={handleSubmit} className="d-flex flex-column h-100">

                            <FormInput
                              error={errors.name}
                              id="name"
                              key={'name'}
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder={t("NAME")}
                              touched={touched.name}
                              type="text"
                              value={values.name}

                            />

                            <FormInput
                              error={errors.email}
                              id="email"
                              key={'email'}
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder={t("EMAIL ADDRESS")}
                              touched={touched.email}
                              type="text"
                              value={values.email}
                            />

                            <FormInput
                              error={errors.subject}
                              id="subject"
                              key={'subject'}
                              name="subject"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder={t("SUBJECT")}
                              touched={touched.subject}
                              type="text"
                              value={values.subject}
                            />

                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.message && !!errors.message}
                              placeholder={t("MESSAGE")}
                            />
                            {touched.message && errors.message ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            ) : null}

                            <div>
                              <br></br>
                              <Captcha
                                reCaptchaRef={reCaptchaRef}
                                //sitekey="YOUR_RECAPTCHA_SITE_KEY" // Replace with your reCAPTCHA site key
                                onChangeCaptchaCode={(
                                  value
                                ) => {
                                  setCaptcha(value);
                                }}
                              />
                            </div>

                            <br></br>
                            <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting} onClick={handleSubmit}>
                              {isSubmitting ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                  <span className="sr-only">{t("LOADING")}...</span>
                                </div>
                              ) : (
                                t("SUBMIT")
                              )}
                            </button>
                          </FormikForm>
                        </>
                      )}
                    </Formik>
                  </div>
                </Col> 
              </Row>

            </Col>
          </Row>
        </Container>

      </section> */}

    </>
  )
}
