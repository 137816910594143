import React, { useContext, useState } from 'react';
import { Badge, Button, Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { FaBell, FaCaretDown } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Redlogo from '../../assets/images/redlogo.png';
import newone from '../../assets/images/newone.png';
import { AuthenticationContext } from '../../contexts/authentication.context';
import './header.scss';
import WhiteLogo from "../../assets/images/whitelogo.png"
import downone from "../../assets/images/downone.png"
import { I18nextProvider, useTranslation } from "react-i18next"
import { getLocalStorage, setLocalStorage } from '../../utils/storage';
import Loader from "../../components/Loader";


export default function Header({ isActiveSidebar, toggleSidebarButton }) {
    const { logout } = useContext(AuthenticationContext)
    const [loading, setLoading] = useState(false);

    const BASE_URL = process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : 'https://demo.negassubsidy.in/qa/admin/home';
    //const ADMIN_URL = process.env.REACT_APP_ADMIN_URL ? process.env.REACT_APP_ADMIN_URL : 'https://demo.negassubsidy.in/qa/admin/login';
    const ADMIN_URL = 'https://negassubsidy.in/login';

    const imageUrl = JSON.parse(localStorage.getItem("imageUrl"));
    const firstName = JSON.parse(localStorage.getItem("firstName"));
    const lastName = JSON.parse(localStorage.getItem("lastName"));
    const companyTitle = JSON.parse(localStorage.getItem("companyTitle"));
    const navigate = useNavigate()

    const { i18n } = useTranslation()

    const { t } = useTranslation()


    const currentLanguage = i18n?.language ?? getLocalStorage('langKey') ?? 'en';

    const changeWebsiteLanguage = ((Language) => {
        setLoading(true)
        let currentLang = 'en';
        if (Language == 'en') {
            currentLang = 'en'
        }
        else if (Language == 'as') {
            currentLang = 'as'
        }
        else {
            currentLang = 'hi'
        }
        //localStorage.setItem("langKey", currentLang);
        setLocalStorage("langKey", currentLang);
        console.log('changeLanguage', currentLang)
        i18n.changeLanguage(currentLang)
        setLoading(false)
    })


    return (
        <React.Fragment>
            {loading ? <Loader isLoading={loading} /> :
                <>

                    <Navbar bg='white' data-bs-theme='light' variant='light' className="py-0 px-md-1 shadow-sm z-2 theme-top-header">
                        <Container className="">
                            <Link to="/home" className="">
                                <Image
                                    className="img-fluid"
                                    src={newone}
                                    alt={`Logo`}
                                    
                                />

                            </Link>
                            <Link to="/home" className="">
                                <Image
                                    className="img-fluid"
                                    src={Redlogo}
                                    alt={`Logo`}
                                    width={88}
                                    height={112}
                                />

                            </Link>
                            <Link to="/home" className="d-inline-block ">
                                <Image
                                    className="img-fluid"
                                    src={WhiteLogo}
                                    alt={`Logo`}
                                    width={88}
                                    height={112}
                                />
                            </Link>
                            <div className='fs-24 fw-bolder max-w-300 rajdhani-bold'>
                                {t("NORTH EAST GAS SUBSIDY SCHEME PORTAL")}
                            </div>
                            <Nav className="ms-auto align-items-center order-md-last header-bangla">


                                <Dropdown className="profileDropdown  me-4">
                                    <Dropdown.Toggle variant="link" id="dropdown-profile" className="border-0 fw-semibold text-decoration-none p-0 text-body">

                                        <span className="align-middle text-start d-none d-md-inline-block ms-1 px-2 text-truncate custom-max-width-150">
                                            <span className='w-100 user-name fs-15 fw-semibold select-langua'>{t("LANGUAGE")}</span>

                                        </span>
                                        <Image
                                            className="img-fluid"
                                            src={downone}
                                            alt={`downone`}

                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end" className="shadow-sm">
                                        <Dropdown.Item onClick={() => changeWebsiteLanguage('hi')} className="fw-medium gap-2 d-flex align-items-center">

                                            {t('HINDI')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeWebsiteLanguage('en')} className="fw-medium gap-2 d-flex align-items-center">
                                            {t('ENGLISH')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeWebsiteLanguage('as')} className="fw-medium gap-2 d-flex align-items-center">
                                            {t('ASSAMESE')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> 

                                <Nav.Link href={ADMIN_URL} className="btn btn-primary w-100 btn-contactUs font-normal" variant='primary'>{t('LOGIN')}</Nav.Link>

                                {/* <Nav.Link href="#contact-us" className="btn btn-primary w-100 btn-contactUs custom-min-width-140  ms-2 font-normal" variant='primary'>{t('CONTACTUS')}</Nav.Link> */}
                            </Nav>
                        </Container>
                    </Navbar>

                    <Navbar expand="lg" className="bg-body-tertiary text-white header-new-menu" data-bs-theme="dark">
                        <Container >

                            <Navbar.Toggle aria-controls="" />
                            <Navbar.Collapse id="">
                                <Nav
                                    className="me-auto my-2 my-lg-0 black-header"
                                >
                                    <Nav.Link href={BASE_URL} className='text-white pe-5 border-end py-03'>{t("HOME")}</Nav.Link>
                                    <Nav.Link href="#about" className='text-white pe-5 ps-lg-5 py-03  border-end'>{t("ABOUT NE GAS SUBSIDY SCHEME")}</Nav.Link>
                                    <Nav.Link href="#subsidized-gas-program" className='text-white pe-5 ps-lg-5 py-03 border-end'>{t("SUBSIDIZED GAS SUPPLY INFORMATION")}</Nav.Link>
                                    <Nav.Link href="#news-and-update" className='text-white py-03 ps-lg-5'>{t("NEWS & UPDATES")}</Nav.Link>

                                </Nav>


                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </>
            }
        </React.Fragment>

    )
}
