import { Form as FormikForm, Formik } from "formik";
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../components/FormInput';
import { validationSchema } from '../../validations/nondomesticconsumer.validation';
import { Button } from "react-bootstrap";
import { handleAddNonDomesticConsumer } from "../../services/nondomesticconsumer.service";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


const DataReviewModal = ({ modal, toggle }) => {
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        handleAddNonDomesticConsumer(values).then(response => {
            toast.success(response.data.message);
            navigate("/non-domestic-consumers");
        }).catch((error) => {
            if(error.response.data.fieldErrors){
                toast.error(error.response.data.fieldErrors[0].message);
            }else{
                toast.error(error.response.data.detail);
            }
        });
    };

    return (
        <Modal className="non-domestic-consumer-modal-cover" isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='border-0 fs-16 fw-semibold' toggle={toggle}>Add Non-Domestic Consumer</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        consumerName: "",
                        consumerKey: "",
                        consumerCategory: "",
                        furtherSupplyTo: "",
                        gstNumber: "",
                        udyogAadharNumber: "",
                        sapErpCode: "",
                        state: "",
                        district: "",
                        customerLevel: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <FormikForm>
                            <FormInput
                                error={errors.consumerName}
                                id="consumerName"
                                key={"consumerName"}
                                label="Consumer Name"
                                name="consumerName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter consumer name"
                                touched={touched.consumerName}
                                type="text"
                                value={values.consumerName || ""}
                            />
                            <FormInput
                                error={errors.consumerKey}
                                id="consumerKey"
                                key={"consumerKey"}
                                label="Consumer Key"
                                name="consumerKey"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter consumer key"
                                touched={touched.consumerKey}
                                type="text"
                                value={values.consumerKey || ""}
                            />
                            <FormInput
                                error={errors.consumerCategory}
                                id="consumerCategory"
                                key={"consumerCategory"}
                                label="Consumer Category"
                                name="consumerCategory"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter consumer category"
                                touched={touched.consumerCategory}
                                type="text"
                                value={values.consumerCategory || ""}
                            />
                            <FormInput
                                error={errors.furtherSupplyTo}
                                id="furtherSupplyTo"
                                key={"furtherSupplyTo"}
                                label="Further Supply To"
                                name="furtherSupplyTo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter further supply to"
                                touched={touched.furtherSupplyTo}
                                type="text"
                                value={values.furtherSupplyTo || ""}
                            />
                            <FormInput
                                error={errors.gstNumber}
                                id="gstNumber"
                                key={"gstNumber"}
                                label="GST Number"
                                name="gstNumber"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter GST number"
                                touched={touched.gstNumber}
                                type="text"
                                value={values.gstNumber || ""}
                            />
                            <FormInput
                                error={errors.udyogAadharNumber}
                                id="udyogAadharNumber"
                                key={"udyogAadharNumber"}
                                label="Udyog Aadhar Number"
                                name="udyogAadharNumber"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter Udyog Aadhar number"
                                touched={touched.udyogAadharNumber}
                                type="text"
                                value={values.udyogAadharNumber || ""}
                            />
                            <FormInput
                                error={errors.sapErpCode}
                                id="sapErpCode"
                                key={"sapErpCode"}
                                label="SAP/ERP Code"
                                name="sapErpCode"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter SAP/ERP code"
                                touched={touched.sapErpCode}
                                type="text"
                                value={values.sapErpCode || ""}
                            />
                            <FormInput
                                error={errors.state}
                                id="state"
                                key={"state"}
                                label="State"
                                name="state"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter state"
                                touched={touched.state}
                                type="text"
                                value={values.state || ""}
                            />
                            <FormInput
                                error={errors.district}
                                id="district"
                                key={"district"}
                                label="District"
                                name="district"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter district"
                                touched={touched.district}
                                type="text"
                                value={values.district || ""}
                            />
                            <FormInput
                                error={errors.customerLevel}
                                id="customerLevel"
                                key={"customerLevel"}
                                label="Customer Level"
                                name="customerLevel"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter customer level"
                                touched={touched.customerLevel}
                                type="text"
                                value={values.customerLevel || ""}
                            />
                        </FormikForm>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter className='border-0'>
                <Button className="fs-14 fw-semibold" variant="outline-dark" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button className="fs-14 fw-semibold" variant="primary" onClick={onSubmit}>
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DataReviewModal;
