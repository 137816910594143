
import { Form as FormikForm, Formik } from "formik";
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../components/FormInput';
import { validationSchema } from '../../validations/goals.validation';
import { Button } from "react-bootstrap";

const GoalModal = ({ modal, toggle }) => {
    const onSubmit = async () => { }
    return (
        <Modal className="goal-modal-cover" isOpen={modal} toggle={toggle} centered >
            <ModalHeader className='border-0 fs-16 fw-semibold' toggle={toggle}>Edit Goal</ModalHeader>
            <ModalBody >
                <Formik
                    initialValues={{
                        email:
                            localStorage.getItem("email") || "",
                        password:
                            localStorage.getItem("password") ||
                            "",
                        rememberMe: localStorage.getItem(
                            "email"
                        )
                            ? true
                            : false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <FormikForm>

                            <FormInput
                                autoComplete="username"
                                error={errors.email}
                                id="email"
                                key={"email"}
                                label="Email"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter email"
                                touched={touched.email}
                                type="text"
                                value={values.email || ""}
                            />

                            <FormInput
                                autoComplete="current-password"
                                error={errors.password}
                                id="password"
                                key={"password"}
                                label="Password"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter password"
                                touched={touched.password}
                                type="password"
                                value={values.password || ""}
                            />




                        </FormikForm>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter className='border-0'>
                <Button className="fs-14 fw-semibold" variant="outline-dark" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button className="fs-14 fw-semibold" variant="primary" onClick={toggle}>
                    Update
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default GoalModal;
