import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Stack } from "reactstrap";

import Header from "./Header";
import SearchForm from "./SearchForm";
import ConsumerCategoryTable from "./ConsumerCategoryTable";
import ConsumerCategoryModal from "./ConsumerCategoryModal";
import { handleGetConsumerCategories, handleDeleteConsumerCategory } from "../../services/consumercategory.service"; // Update the import to include delete function
import toast from 'react-hot-toast';
import SvgIcons from "../../components/SVGIcons"
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";
import Swal from "sweetalert2";
export default function ConsumerCategoryList() {
    const location = useLocation();
    const navigate = useNavigate()
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });
    const [modal, setModal] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
    });

    const permission = useRef({ addModule : false, editModule : false, deleteModule : false });
    useEffect(()=>{
        isAdminUser().then(response=>{
            if(response){
                permission.current.addModule = true;
                permission.current.editModule = true;
                permission.current.deleteModule = true;
            }else{
                getModulePermissions("Consumer Category Master").then(response => {
                    if(response.includes("CONSUMER_CATEGORY_CREATE")){
                        permission.current.addModule = true;
                    }
                    if(response.includes("CONSUMER_CATEGORY_UPDATE")){
                        permission.current.editModule = true;
                    }
                    if(response.includes("CONSUMER_CATEGORY_DELETE")){
                        permission.current.deleteModule = true;
                    }
                }).catch(error => {
                    console.error("Error fetching permissions:", error);
                });
            }
        }).catch(error=>{
            console.error("Error get during to fetch User Type", error);
        })
        
    },[]);

    const toggle = () => setModal(!modal);

    const editConsumerCategory = async (id) => {
        navigate(`/consumer-categories/edit/${id}`);
    };

    const deleteConsumerCategory = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this consumer category?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    handleDeleteConsumerCategory(id).then((responseDelete) => {
                        toast.success("Consumer category deleted successfully");
                        dataQuery.refetch();
                    }).catch((error) => {
                        toast.error(error.response.data.detail);
                    })
                } catch (error) {
                    toast.error(error.response.data.detail);
                }
            } else {

            }
        })
    };

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return handleGetConsumerCategories({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return handleGetConsumerCategories({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });

    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
    }, [dataQuery.data?.data?.totalPages]);

    const columns = React.useMemo(
        () => [
            {
                accessorFn: (row) => row.categoryName,
                id: "categoryName",
                header: () => "Category Name",
            },
            {
                accessorFn: (row) => row.categoryCode != null  ? row.categoryCode : '-',
                id: "categoryCode",
                header: () => "Category Code",
                enableSorting: true,
            },
            {
                accessorFn: (row) => row.description,
                id: "description",
                header: () => "Description",
            },
            {
                id: "actions",
                isAction: true,
                cell: (info) => {
                    return (
                        <div className="d-flex items-center gap-2 justify-content-center">
                            { permission.current.editModule ? 
                            <div
                                onClick={() => {
                                    console.log("Info::", info.row.original.id);
                                    editConsumerCategory(info.row.original.id);
                                }}
                            >
                                <span className=''>{SvgIcons.editIcon}</span>
                            </div> : ""}

                            { permission.current.deleteModule ?
                            <div
                                onClick={() => {
                                    deleteConsumerCategory(info.row.original.id);
                                }}
                            >
                                <span className=''>{SvgIcons.deleteIcon}</span>
                            </div> : ""}
                        </div>
                    );
                },
                header: () => <div className="d-flex justify-content-center">Actions</div>,
                enableSorting: false,
            },
        ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
            { permission.current.addModule ? <Header toggle={toggle} /> : "" }
                <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <SearchForm filter={filter} setFilter={setFilter} />
                        <ConsumerCategoryTable
                            columns={columns}
                            dataQuery={dataQuery}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </Card>
                </div>
            </div>
            <ConsumerCategoryModal modal={modal} toggle={toggle} />
        </React.Fragment>
    );
}
