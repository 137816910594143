/**
 * ALL available SVG icons which can be use in application
 */
const SvgIcons = {
    dashboardIcon: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 6V0H18V6H10ZM0 10V0H8V10H0ZM10 18V8H18V18H10ZM0 18V12H8V18H0ZM2 8H6V2H2V8ZM12 16H16V10H12V16ZM12 4H16V2H12V4ZM2 16H6V14H2V16Z" fill="white"/>
    </svg>,
    eyeIcon: <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 9C9.9375 9 10.7344 8.67188 11.3906 8.01562C12.0469 7.35938 12.375 6.5625 12.375 5.625C12.375 4.6875 12.0469 3.89062 11.3906 3.23438C10.7344 2.57812 9.9375 2.25 9 2.25C8.0625 2.25 7.26562 2.57812 6.60938 3.23438C5.95312 3.89062 5.625 4.6875 5.625 5.625C5.625 6.5625 5.95312 7.35938 6.60938 8.01562C7.26562 8.67188 8.0625 9 9 9ZM9 7.65C8.4375 7.65 7.95938 7.45313 7.56563 7.05938C7.17188 6.66563 6.975 6.1875 6.975 5.625C6.975 5.0625 7.17188 4.58437 7.56563 4.19063C7.95938 3.79688 8.4375 3.6 9 3.6C9.5625 3.6 10.0406 3.79688 10.4344 4.19063C10.8281 4.58437 11.025 5.0625 11.025 5.625C11.025 6.1875 10.8281 6.66563 10.4344 7.05938C10.0406 7.45313 9.5625 7.65 9 7.65ZM9 11.25C7.175 11.25 5.5125 10.7406 4.0125 9.72188C2.5125 8.70312 1.425 7.3375 0.75 5.625C1.425 3.9125 2.5125 2.54688 4.0125 1.52813C5.5125 0.509375 7.175 0 9 0C10.825 0 12.4875 0.509375 13.9875 1.52813C15.4875 2.54688 16.575 3.9125 17.25 5.625C16.575 7.3375 15.4875 8.70312 13.9875 9.72188C12.4875 10.7406 10.825 11.25 9 11.25ZM9 9.75C10.4125 9.75 11.7094 9.37813 12.8906 8.63437C14.0719 7.89062 14.975 6.8875 15.6 5.625C14.975 4.3625 14.0719 3.35938 12.8906 2.61562C11.7094 1.87187 10.4125 1.5 9 1.5C7.5875 1.5 6.29063 1.87187 5.10938 2.61562C3.92812 3.35938 3.025 4.3625 2.4 5.625C3.025 6.8875 3.92812 7.89062 5.10938 8.63437C6.29063 9.37813 7.5875 9.75 9 9.75Z" fill="black"/>
    </svg>,

    eyeOffIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16.003" height="13.72" viewBox="0 0 16.003 13.72"><path d="M-6278.344-456.854l-12.7-12.708a.494.494,0,0,1,0-.714.518.518,0,0,1,.727,0l12.7,12.708a.51.51,0,0,1,0,.715.489.489,0,0,1-.234.132.408.408,0,0,1-.133.018A.494.494,0,0,1-6278.344-456.854Zm-13.852-6.334a.545.545,0,0,1,.023-.761,22.813,22.813,0,0,1,3.031-2.906l1.9,1.912a2.8,2.8,0,0,0-.3,1.225,3.132,3.132,0,0,0,.93,2.424,3.24,3.24,0,0,0,2.43.938,3.178,3.178,0,0,0,1.219-.305l1.563,1.555a6.885,6.885,0,0,1-2.937.686C-6287.828-458.422-6289.883-460.626-6292.2-463.188Zm10.758,1a3.349,3.349,0,0,0,.32-1.227,3.264,3.264,0,0,0-.953-2.422,3.208,3.208,0,0,0-2.43-.939,3.158,3.158,0,0,0-1.211.305l-1.57-1.561a6.764,6.764,0,0,1,2.945-.679c3.406,0,6.008,2.493,7.867,4.762a.556.556,0,0,1-.016.739,23.867,23.867,0,0,1-3.039,2.93Zm-3.062.9a2.276,2.276,0,0,1-2.109-2.1,2.034,2.034,0,0,1,.078-.839l2.844,2.851a2.119,2.119,0,0,1-.641.1C-6284.391-461.28-6284.461-461.283-6284.5-461.287Zm1.68-2.283c.039,0,.078,0,.109,0a1.652,1.652,0,0,0,.672-.14v.14a2.152,2.152,0,0,1-.109.665Zm-2.18-2.186a2.2,2.2,0,0,1,.664-.1l.117,0a1.778,1.778,0,0,0-.117.6c0,.05.008.107.008.161Z" transform="translate(6292.339 470.426)" fill="currentColor" /></svg>,

    infoCircleIcon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path id="information" d="M9.7,6.9H8.3V5.5H9.7m0,7H8.3V8.3H9.7M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Z" transform="translate(-1.999 -1.998)" fill="currentColor" /></svg>,

    supplierIcons: <svg xmlns="http://www.w3.org/2000/svg" width="22.033" height="17" viewBox="0 0 22.033 17"><path fill="currentColor" d="M13.428 16.999a.651.651 0 0 1-.65-.645V8.389a.651.651 0 0 1 .65-.645h2.014v2.555a.647.647 0 0 0 .932.578l1.035-.524.986.493a.627.627 0 0 0 .34.1.646.646 0 0 0 .65-.645V7.742h2a.648.648 0 0 1 .645.645v7.965a.648.648 0 0 1-.645.645Zm-12.783 0A.643.643 0 0 1 0 16.354v-2.012a4.22 4.22 0 0 1 3.205-4.123l2.232-.574a.632.632 0 0 1 .68.255l1.422 1.869L8.955 9.9a.636.636 0 0 1 .668-.233l1.863.466v6.226a1.9 1.9 0 0 0 .113.641ZM17.7 9.066a.64.64 0 0 0-.578 0l-.391.193V7.744h1.363v1.515ZM3.192 4.351A4.348 4.348 0 1 1 7.54 8.699a4.346 4.346 0 0 1-4.349-4.352Z" /></svg>,

    customerIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16.232" height="20.998" viewBox="0 0 16.232 20.998"><path fill="currentColor" d="M3.436 20.699c-1.792-.332-3.961-1.054-3.321-3.424.291-1.07 1.583-1.854 2.76-2.5a16.859 16.859 0 0 1 1.734-.869c.57-.23 1.362-.435 1.432-1.177.062-.623-.336-.96-.714-1.481a7.4 7.4 0 0 1-1.533-5.007 4.135 4.135 0 0 1 3.728-4.085c3.088-.353 5.241 1.7 4.954 5.213a7.766 7.766 0 0 1-.742 2.571 5.729 5.729 0 0 1-.923.2 1.256 1.256 0 0 0-.779-.271h-.869a1.259 1.259 0 0 0 0 2.518h.869a1.621 1.621 0 0 0 .246-.025 1.388 1.388 0 0 0 0 .673c.173.66 1.62 1.05 2.146 1.28 1.337.586 3.207 1.46 3.679 2.859a3.216 3.216 0 0 1 0 1.788c-.751 1.94-5.389 2.042-7.969 2.042a26.91 26.91 0 0 1-4.698-.305Zm5.726-8.916a.656.656 0 1 1 0-1.312h.869a.658.658 0 0 1 .55.3 5.385 5.385 0 0 0 2.612-.869 1.126 1.126 0 0 1-.078-.406V7.31a1.092 1.092 0 0 1 .607-.98 5.614 5.614 0 0 0-11.213 0 1.1 1.1 0 0 1 .611.98v2.186a1.1 1.1 0 0 1-1.095 1.095h-.054A1.1 1.1 0 0 1 .876 9.496V7.31a1.091 1.091 0 0 1 .623-.988 6.622 6.622 0 0 1 13.23 0 1.091 1.091 0 0 1 .623.988v2.186a1.1 1.1 0 0 1-1.095 1.095h-.054a1.061 1.061 0 0 1-.586-.176 5.862 5.862 0 0 1-3.011 1.013.649.649 0 0 1-.578.357Z" /></svg>,

    catalogIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18.118 22.002"><path fill="currentColor" d="M5.178 22.002V3.883h12.94v18.119Zm2.589-2.589h7.763v-1.294H7.767Zm0-2.589h7.763V15.53H7.767Zm0-2.589h7.763V6.472H7.767ZM0 19.412V.002h8.2L3.885 2.591v16.821Zm9.057-6.472v-.938l1.405-.916 1.278.526 1.282-.953 1.212.715v1.57Zm0-5.174h5.178v2.1l-1.29-.764-1.389 1.031-1.229-.51-1.27.83ZM6.041 2.588l4.311-2.589v2.589Z" /></svg>,

    productIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20.098" height="20" viewBox="0 0 20.098 20"><path fill="currentColor" d="M8.352 9.1a4.784 4.784 0 0 0 .86.268V20a2.947 2.947 0 0 1-.525-.19l-6.7-2.981A3.349 3.349 0 0 1 0 13.77V6.334a3.3 3.3 0 0 1 .123-.882ZM13.2 6.613 4.1 2.336l-2.11.938a3.248 3.248 0 0 0-1.016.7l8.05 3.584a2.532 2.532 0 0 0 2.054 0Zm5.929-2.635a3.249 3.249 0 0 0-1.016-.7L11.411.293a3.312 3.312 0 0 0-2.724 0L6.13 1.432l9.1 4.276Zm.848 1.474-3.921 1.741v2.736a.837.837 0 0 1-1.675 0v-2L11.746 9.1a5.894 5.894 0 0 1-.86.268V20a2.944 2.944 0 0 0 .525-.19l6.7-2.981A3.349 3.349 0 0 0 20.1 13.77V6.334a3.3 3.3 0 0 0-.125-.882Z" /></svg>,

    userManagementIcon:
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 9C6.9 9 5.95833 8.60833 5.175 7.825C4.39167 7.04167 4 6.1 4 5V1.5C4 1.08333 4.14583 0.729167 4.4375 0.4375C4.72917 0.145833 5.08333 0 5.5 0C5.75 0 5.9875 0.0583333 6.2125 0.175C6.4375 0.291667 6.61667 0.458333 6.75 0.675C6.88333 0.458333 7.0625 0.291667 7.2875 0.175C7.5125 0.0583333 7.75 0 8 0C8.25 0 8.4875 0.0583333 8.7125 0.175C8.9375 0.291667 9.11667 0.458333 9.25 0.675C9.38333 0.458333 9.5625 0.291667 9.7875 0.175C10.0125 0.0583333 10.25 0 10.5 0C10.9167 0 11.2708 0.145833 11.5625 0.4375C11.8542 0.729167 12 1.08333 12 1.5V5C12 6.1 11.6083 7.04167 10.825 7.825C10.0417 8.60833 9.1 9 8 9ZM8 7C8.55 7 9.02083 6.80417 9.4125 6.4125C9.80417 6.02083 10 5.55 10 5V2.5H6V5C6 5.55 6.19583 6.02083 6.5875 6.4125C6.97917 6.80417 7.45 7 8 7ZM0 17V14.2C0 13.6333 0.145833 13.1125 0.4375 12.6375C0.729167 12.1625 1.11667 11.8 1.6 11.55C2.63333 11.0333 3.68333 10.6458 4.75 10.3875C5.81667 10.1292 6.9 10 8 10C9.1 10 10.1833 10.1292 11.25 10.3875C12.3167 10.6458 13.3667 11.0333 14.4 11.55C14.8833 11.8 15.2708 12.1625 15.5625 12.6375C15.8542 13.1125 16 13.6333 16 14.2V17H0ZM2 15H14V14.2C14 14.0167 13.9542 13.85 13.8625 13.7C13.7708 13.55 13.65 13.4333 13.5 13.35C12.6 12.9 11.6917 12.5625 10.775 12.3375C9.85833 12.1125 8.93333 12 8 12C7.06667 12 6.14167 12.1125 5.225 12.3375C4.30833 12.5625 3.4 12.9 2.5 13.35C2.35 13.4333 2.22917 13.55 2.1375 13.7C2.04583 13.85 2 14.0167 2 14.2V15Z" fill="white"/>
    </svg>
    
    ,
    manageTemplate: <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.40337 12.3566C8.16337 12.5432 7.82337 12.5432 7.58337 12.3566L3.48337 9.16991C3.24337 8.98325 2.91004 8.98325 2.67004 9.16991C2.33004 9.43658 2.33004 9.94991 2.67004 10.2166L7.17671 13.7232C7.65671 14.0966 8.33004 14.0966 8.81671 13.7232L13.3234 10.2166C13.6634 9.94991 13.6634 9.43658 13.3234 9.16991L13.3167 9.16325C13.0767 8.97658 12.7434 8.97658 12.5034 9.16325L8.40337 12.3566ZM8.82337 10.3432L13.33 6.83658C13.67 6.56991 13.67 6.04991 13.33 5.78325L8.82337 2.27658C8.34337 1.90325 7.67004 1.90325 7.18337 2.27658L2.67671 5.78992C2.33671 6.05658 2.33671 6.57658 2.67671 6.84325L7.18337 10.3499C7.66337 10.7232 8.34337 10.7232 8.82337 10.3432Z" />
    </svg>,
    llmConnection:

        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 3.33333V2.66667C13.3333 2.3 13.0333 2 12.6667 2H11.3333C10.9667 2 10.6667 2.3 10.6667 2.66667V3.33333C10.3 3.33333 10 3.63333 10 4V6C10 6.36667 10.3 6.66667 10.6667 6.66667H11.3333V11.2733C11.3333 11.9667 10.8267 12.5933 10.14 12.66C9.34 12.74 8.66667 12.1133 8.66667 11.3333V4.76C8.66667 3.34 7.59333 2.1 6.17333 2.00667C4.62 1.90667 3.33333 3.13333 3.33333 4.66667V9.33333H2.66667C2.3 9.33333 2 9.63333 2 10V12C2 12.3667 2.3 12.6667 2.66667 12.6667V13.3333C2.66667 13.7 2.96667 14 3.33333 14H4.66667C5.03333 14 5.33333 13.7 5.33333 13.3333V12.6667C5.7 12.6667 6 12.3667 6 12V10C6 9.63333 5.7 9.33333 5.33333 9.33333H4.66667V4.72667C4.66667 4.03333 5.17333 3.40667 5.86 3.34C6.66 3.26 7.33333 3.88667 7.33333 4.66667V11.24C7.33333 12.66 8.40667 13.9 9.82667 13.9933C11.38 14.0933 12.6667 12.8667 12.6667 11.3333V6.66667H13.3333C13.7 6.66667 14 6.36667 14 6V4C14 3.63333 13.7 3.33333 13.3333 3.33333Z" />
        </svg>
    ,

    goalsIcon: < svg width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" >
        <path fill="currentColor" d="M12.2047 3.77816C11.9647 4.01816 11.9447 4.39149 12.1581 4.65816C13.1247 5.87149 13.6314 7.53149 13.1781 9.27149C12.6514 11.3048 11.0581 12.8248 9.03805 13.2315C5.63805 13.9048 2.65805 11.2782 2.65805 7.99149C2.65805 5.27149 4.69139 3.03149 7.32472 2.70482V4.05149C5.23805 4.40482 3.70472 6.35816 4.03805 8.60482C4.29805 10.3448 5.74472 11.7448 7.49139 11.9582C9.91806 12.2582 11.9914 10.3648 11.9914 7.99149C11.9914 7.15816 11.7381 6.39149 11.3047 5.75149C11.0781 5.41816 10.5914 5.39816 10.3047 5.67816L10.2981 5.68482C10.0714 5.91149 10.0514 6.26482 10.2247 6.53149C10.6247 7.14482 10.7847 7.93149 10.5514 8.74482C10.2914 9.65816 9.52472 10.3848 8.59139 10.5915C6.85806 10.9715 5.32472 9.66482 5.32472 7.99149C5.32472 6.75149 6.17806 5.71816 7.32472 5.41816V6.84482C6.92472 7.07816 6.65805 7.49816 6.65805 7.99149C6.65805 8.72482 7.25806 9.32482 7.99139 9.32482C8.72472 9.32482 9.32472 8.72482 9.32472 7.99149C9.32472 7.49816 9.05806 7.07149 8.65806 6.84482V1.79816C8.65806 1.53816 8.44472 1.32482 8.18472 1.32482C4.61139 1.19149 1.53139 4.03149 1.33805 7.59149C1.09805 11.9582 4.94472 15.4715 9.34472 14.5248C11.9314 13.9715 13.9314 11.9915 14.5114 9.41149C14.9847 7.30482 14.3781 5.30482 13.1981 3.83149C12.9514 3.51816 12.4847 3.49816 12.2047 3.77816Z" />
    </svg >
    ,
    settings:
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 7.99992C13 7.84659 12.9934 7.69992 12.98 7.54659L14.22 6.60659C14.4867 6.40659 14.56 6.03325 14.3934 5.73992L13.1467 3.58659C12.98 3.29325 12.62 3.17325 12.3134 3.30659L10.88 3.91325C10.6334 3.73992 10.3734 3.58659 10.1 3.45992L9.90669 1.91992C9.86669 1.58659 9.58002 1.33325 9.24669 1.33325H6.76002C6.42002 1.33325 6.13335 1.58659 6.09335 1.91992L5.90002 3.45992C5.62669 3.58659 5.36669 3.73992 5.12002 3.91325L3.68669 3.30659C3.38002 3.17325 3.02002 3.29325 2.85335 3.58659L1.60669 5.74659C1.44002 6.03992 1.51335 6.40659 1.78002 6.61325L3.02002 7.55325C3.00669 7.69992 3.00002 7.84659 3.00002 7.99992C3.00002 8.15325 3.00669 8.29992 3.02002 8.45325L1.78002 9.39325C1.51335 9.59325 1.44002 9.96659 1.60669 10.2599L2.85335 12.4133C3.02002 12.7066 3.38002 12.8266 3.68669 12.6933L5.12002 12.0866C5.36669 12.2599 5.62669 12.4133 5.90002 12.5399L6.09335 14.0799C6.13335 14.4133 6.42002 14.6666 6.75335 14.6666H9.24002C9.57335 14.6666 9.86002 14.4133 9.90002 14.0799L10.0934 12.5399C10.3667 12.4133 10.6267 12.2599 10.8734 12.0866L12.3067 12.6933C12.6134 12.8266 12.9734 12.7066 13.14 12.4133L14.3867 10.2599C14.5534 9.96659 14.48 9.59992 14.2134 9.39325L12.9734 8.45325C12.9934 8.29992 13 8.15325 13 7.99992ZM8.02669 10.3333C6.74002 10.3333 5.69335 9.28659 5.69335 7.99992C5.69335 6.71325 6.74002 5.66659 8.02669 5.66659C9.31335 5.66659 10.36 6.71325 10.36 7.99992C10.36 9.28659 9.31335 10.3333 8.02669 10.3333Z" />
        </svg>,
    cms:
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.44667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V6.55333C14 6.2 13.86 5.86 13.6067 5.61333L10.3867 2.39333C10.14 2.14 9.8 2 9.44667 2ZM5.33333 10H10.6667C11.0333 10 11.3333 10.3 11.3333 10.6667C11.3333 11.0333 11.0333 11.3333 10.6667 11.3333H5.33333C4.96667 11.3333 4.66667 11.0333 4.66667 10.6667C4.66667 10.3 4.96667 10 5.33333 10ZM5.33333 7.33333H10.6667C11.0333 7.33333 11.3333 7.63333 11.3333 8C11.3333 8.36667 11.0333 8.66667 10.6667 8.66667H5.33333C4.96667 8.66667 4.66667 8.36667 4.66667 8C4.66667 7.63333 4.96667 7.33333 5.33333 7.33333ZM5.33333 4.66667H8.66667C9.03333 4.66667 9.33333 4.96667 9.33333 5.33333C9.33333 5.7 9.03333 6 8.66667 6H5.33333C4.96667 6 4.66667 5.7 4.66667 5.33333C4.66667 4.96667 4.96667 4.66667 5.33333 4.66667Z" />
        </svg>
    ,
    savingChallenges:
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2199 5.33327L11.7066 3.81994C11.7533 3.53994 11.8266 3.27994 11.9199 3.05327C11.9933 2.87994 12.0199 2.67994 11.9799 2.47327C11.8933 1.99327 11.4266 1.65994 10.9333 1.6666C9.87325 1.6866 8.93325 2.2066 8.33325 2.99994H4.99992C2.97325 2.99994 1.33325 4.63994 1.33325 6.6666C1.33325 8.1666 2.24659 11.6533 2.71992 13.3599C2.87992 13.9333 3.40659 14.3333 4.00659 14.3333H5.33325C6.06659 14.3333 6.66659 13.7333 6.66659 12.9999H7.99992C7.99992 13.7333 8.59992 14.3333 9.33325 14.3333H10.6733C11.2599 14.3333 11.7799 13.9466 11.9533 13.3799L12.7866 10.6066L14.2133 10.1266C14.4866 10.0333 14.6666 9.77993 14.6666 9.49327V5.99994C14.6666 5.63327 14.3666 5.33327 13.9999 5.33327H13.2199ZM7.99992 6.33327H5.99992C5.63325 6.33327 5.33325 6.03327 5.33325 5.6666C5.33325 5.29994 5.63325 4.99994 5.99992 4.99994H7.99992C8.36659 4.99994 8.66659 5.29994 8.66659 5.6666C8.66659 6.03327 8.36659 6.33327 7.99992 6.33327ZM10.6666 7.6666C10.2999 7.6666 9.99992 7.3666 9.99992 6.99994C9.99992 6.63327 10.2999 6.33327 10.6666 6.33327C11.0333 6.33327 11.3333 6.63327 11.3333 6.99994C11.3333 7.3666 11.0333 7.6666 10.6666 7.6666Z" />
        </svg>
    ,

    reactSelectArrow: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.77083 7.49998L10.0042 10.7333L13.2375 7.49998C13.5625 7.17498 14.0875 7.17498 14.4125 7.49998C14.7375 7.82498 14.7375 8.34998 14.4125 8.67498L10.5875 12.5C10.2625 12.825 9.7375 12.825 9.4125 12.5L5.5875 8.67498C5.2625 8.34998 5.2625 7.82498 5.5875 7.49998C5.9125 7.18331 6.44583 7.17498 6.77083 7.49998Z" fill="black" />
    </svg>,

    calendarIcon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8333 3.33335H15V2.50002C15 2.04169 14.625 1.66669 14.1667 1.66669C13.7083 1.66669 13.3333 2.04169 13.3333 2.50002V3.33335H6.66667V2.50002C6.66667 2.04169 6.29167 1.66669 5.83333 1.66669C5.375 1.66669 5 2.04169 5 2.50002V3.33335H4.16667C3.24167 3.33335 2.50833 4.08335 2.50833 5.00002L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00002C17.5 4.08335 16.75 3.33335 15.8333 3.33335ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667H5C4.54167 16.6667 4.16667 16.2917 4.16667 15.8334V7.50002H15.8333V15.8334ZM5.83333 9.16669H7.5V10.8334H5.83333V9.16669ZM9.16667 9.16669H10.8333V10.8334H9.16667V9.16669ZM12.5 9.16669H14.1667V10.8334H12.5V9.16669Z" fill="black" />
    </svg>
    ,
    orderIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18.429" viewBox="0 0 15 18.429"><path fill="currentColor" fillRule="evenodd" d="M15 1.5v16.286a.643.643 0 0 1-1.005.531l-2.139-1.46-2.022 1.451a.641.641 0 0 1-.8-.039L7.5 16.926l-1.536 1.343a.641.641 0 0 1-.8.039l-2.02-1.451-2.139 1.46A.643.643 0 0 1 0 17.786V1.5A1.5 1.5 0 0 1 1.5 0h12A1.5 1.5 0 0 1 15 1.5ZM4.071 8.143h6.857a.643.643 0 0 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Zm0-3.429h6.857a.643.643 0 0 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Zm0 6.857H7.5a.643.643 0 1 0 0-1.286H4.071a.643.643 0 1 0 0 1.286Z" /></svg>,

    quotationIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="17.998" viewBox="0 0 13.5 17.998"><path fill="currentColor" d="M1.124 17.999A1.126 1.126 0 0 1 0 16.875V1.123A1.126 1.126 0 0 1 1.124-.001h6.749v4.5a1.129 1.129 0 0 0 1.124 1.128h4.5v11.248a1.126 1.126 0 0 1-1.124 1.124Zm1.123-2.813a.568.568 0 0 0 .566.566h7.873a.564.564 0 0 0 0-1.128H2.813a.567.567 0 0 0-.566.56Zm0-2.247a.564.564 0 0 0 .566.562h7.873a.562.562 0 0 0 0-1.124H2.813a.564.564 0 0 0-.566.56Zm5.626-2.251a.563.563 0 0 0 .563.562h2.251a.562.562 0 0 0 0-1.124H8.436a.563.563 0 0 0-.563.56Zm-4.97-1.874a.561.561 0 0 0 .082.791 2.323 2.323 0 0 0 .951.451v.632a.562.562 0 0 0 1.124 0v-.632a2.039 2.039 0 0 0 1.689-1.9 2.125 2.125 0 0 0-2.251-1.968c-.607 0-1.124-.385-1.124-.845s.517-.845 1.124-.845a1.275 1.275 0 0 1 .8.267.561.561 0 1 0 .709-.869 2.322 2.322 0 0 0-.951-.451v-.631a.562.562 0 0 0-1.124 0v.631a2.039 2.039 0 0 0-1.689 1.9 2.125 2.125 0 0 0 2.252 1.968c.61 0 1.123.385 1.123.845s-.513.845-1.123.845a1.263 1.263 0 0 1-.8-.271.568.568 0 0 0-.355-.125.558.558 0 0 0-.441.205Zm4.97-.377a.567.567 0 0 0 .563.566h2.251a.564.564 0 0 0 0-1.128H8.436a.566.566 0 0 0-.563.56Zm1.126-3.938v-4.5l4.5 4.5Z" /></svg>,

    invoiceIcon: <svg xmlns="http://www.w3.org/2000/svg" width="13.8" height="18" viewBox="0 0 13.8 18"><path fill="currentColor" d="M13.49 4.5c-.274.008-.872 0-2.39 0a1.8 1.8 0 0 1-1.8-1.8c0-1.518-.006-2.117 0-2.39A.3.3 0 0 0 9 0H1.2A1.2 1.2 0 0 0 0 1.2v15.6A1.2 1.2 0 0 0 1.2 18h11.4a1.2 1.2 0 0 0 1.2-1.2v-12a.3.3 0 0 0-.31-.3ZM2.475 3.525h2.662a.3.3 0 0 1 0 .6H2.475a.3.3 0 0 1 0-.6Zm0 1.875h4.162a.3.3 0 0 1 0 .6H2.475a.3.3 0 0 1 0-.6Zm9.15 8.85a.3.3 0 0 1-.3.3h-8.85a.3.3 0 0 1-.3-.3V8.1a.3.3 0 0 1 .3-.3h8.85a.3.3 0 0 1 .3.3ZM8.25 11.912H3.075a.3.3 0 0 1-.3-.3v-.873a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.873a.3.3 0 0 1-.3.3ZM9.45 8.4h1.275a.3.3 0 0 1 .3.3v.838a.3.3 0 0 1-.3.3H9.45a.3.3 0 0 1-.3-.3V8.7a.3.3 0 0 1 .3-.3ZM2.775 9.539V8.7a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.838a.3.3 0 0 1-.3.3H3.075a.3.3 0 0 1-.3-.299Zm7.95 2.373H9.45a.3.3 0 0 1-.3-.3v-.873a.3.3 0 0 1 .3-.3h1.275a.3.3 0 0 1 .3.3v.873a.3.3 0 0 1-.3.3ZM8.25 13.95H3.075a.3.3 0 0 1-.3-.3v-.839a.3.3 0 0 1 .3-.3H8.25a.3.3 0 0 1 .3.3v.839a.3.3 0 0 1-.3.3Zm2.775-1.139v.838a.3.3 0 0 1-.3.3H9.45a.3.3 0 0 1-.3-.3v-.838a.3.3 0 0 1 .3-.3h1.275a.3.3 0 0 1 .3.301ZM13.5 3.9h-2.4a1.2 1.2 0 0 1-1.2-1.2V.3a.3.3 0 0 1 .512-.212l3.3 3.3a.3.3 0 0 1-.212.512Z" /></svg>,

    settingIcon: <svg xmlns="http://www.w3.org/2000/svg" width="19.01" height="18" viewBox="0 0 19.01 18"><path fill="currentColor" d="M1.134 6.359A2.484 2.484 0 0 0 3.092 3a1.166 1.166 0 0 1 .349-1.312A9.347 9.347 0 0 1 6.229.073a1.172 1.172 0 0 1 1.324.368 2.478 2.478 0 0 0 .866.7 2.478 2.478 0 0 0 1.086.251 2.478 2.478 0 0 0 1.086-.251 2.478 2.478 0 0 0 .866-.7 1.172 1.172 0 0 1 1.324-.368 9.391 9.391 0 0 1 2.6 1.457 1.179 1.179 0 0 1 .368 1.35 2.471 2.471 0 0 0 2.059 3.4 1.172 1.172 0 0 1 1.027.944A8.967 8.967 0 0 1 19.01 9a9.07 9.07 0 0 1-.12 1.489 1.172 1.172 0 0 1-1.033.982 2.472 2.472 0 0 0-2.015 3.492 1.153 1.153 0 0 1-.323 1.381 9.416 9.416 0 0 1-2.763 1.59 1.267 1.267 0 0 1-.4.07 1.166 1.166 0 0 1-.951-.494 2.452 2.452 0 0 0-2.028-1.064 2.483 2.483 0 0 0-1.99 1 1.166 1.166 0 0 1-1.369.386 9.5 9.5 0 0 1-2.535-1.514 1.172 1.172 0 0 1-.342-1.337 2.471 2.471 0 0 0-1.983-3.415 1.172 1.172 0 0 1-.995-.963A9.188 9.188 0 0 1 0 9a9.093 9.093 0 0 1 .158-1.7 1.16 1.16 0 0 1 .976-.944ZM6.337 9a3.168 3.168 0 1 0 .928-2.24A3.168 3.168 0 0 0 6.337 9Z" /></svg>,

    editIcon: <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 12.25H2.81875L10.15 4.91875L9.08125 3.85L1.75 11.1812V12.25ZM0.25 13.75V10.5625L10.15 0.68125C10.3 0.54375 10.4656 0.4375 10.6469 0.3625C10.8281 0.2875 11.0188 0.25 11.2188 0.25C11.4187 0.25 11.6125 0.2875 11.8 0.3625C11.9875 0.4375 12.15 0.55 12.2875 0.7L13.3188 1.75C13.4688 1.8875 13.5781 2.05 13.6469 2.2375C13.7156 2.425 13.75 2.6125 13.75 2.8C13.75 3 13.7156 3.19062 13.6469 3.37187C13.5781 3.55312 13.4688 3.71875 13.3188 3.86875L3.4375 13.75H0.25ZM9.60625 4.39375L9.08125 3.85L10.15 4.91875L9.60625 4.39375Z" fill="black"/>
    </svg>,

    deleteIcon: <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 13.75C1.8375 13.75 1.48438 13.6031 1.19062 13.3094C0.896875 13.0156 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1031 13.0156 10.8094 13.3094C10.5156 13.6031 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z" fill="black"/>
    </svg>,

    deleteIcon_2: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z" fill="#FB4D4D" /></svg>,

    mastermanage: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_722_1671"  width="24" height="24">
    <rect width="24" height="24" fill="#ffffff"/>
    </mask>
    <g mask="url(#mask0_722_1671)">
    <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8H11.175L9.175 6H4V18L6.4 10H23.5L20.925 18.575C20.7917 19.0083 20.5458 19.3542 20.1875 19.6125C19.8292 19.8708 19.4333 20 19 20H4ZM6.1 18H19L20.8 12H7.9L6.1 18Z" fill="white"/>
    </g>
    </svg>,
    keyIcon : <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25 6.5C4.8375 6.5 4.48438 6.35313 4.19063 6.05937C3.89688 5.76562 3.75 5.4125 3.75 5C3.75 4.5875 3.89688 4.23438 4.19063 3.94063C4.48438 3.64688 4.8375 3.5 5.25 3.5C5.6625 3.5 6.01562 3.64688 6.30937 3.94063C6.60313 4.23438 6.75 4.5875 6.75 5C6.75 5.4125 6.60313 5.76562 6.30937 6.05937C6.01562 6.35313 5.6625 6.5 5.25 6.5ZM5.25 9.5C4 9.5 2.9375 9.0625 2.0625 8.1875C1.1875 7.3125 0.75 6.25 0.75 5C0.75 3.75 1.1875 2.6875 2.0625 1.8125C2.9375 0.9375 4 0.5 5.25 0.5C6.0875 0.5 6.84688 0.70625 7.52813 1.11875C8.20938 1.53125 8.75 2.075 9.15 2.75H15.75L18 5L14.625 8.375L13.125 7.25L11.625 8.375L10.0312 7.25H9.15C8.75 7.925 8.20938 8.46875 7.52813 8.88125C6.84688 9.29375 6.0875 9.5 5.25 9.5ZM5.25 8C5.95 8 6.56563 7.7875 7.09688 7.3625C7.62813 6.9375 7.98125 6.4 8.15625 5.75H10.5L11.5875 6.51875L13.125 5.375L14.4562 6.40625L15.8625 5L15.1125 4.25H8.15625C7.98125 3.6 7.62813 3.0625 7.09688 2.6375C6.56563 2.2125 5.95 2 5.25 2C4.425 2 3.71875 2.29375 3.13125 2.88125C2.54375 3.46875 2.25 4.175 2.25 5C2.25 5.825 2.54375 6.53125 3.13125 7.11875C3.71875 7.70625 4.425 8 5.25 8Z" fill="black"/>
    </svg>,
    consumerIcon: <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.7958 11.5625 17.3875 10.8875C16.9792 10.2125 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42083 18.05 9.6625C18.8 9.90417 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.2542 21.525 11.6625C21.8417 12.0708 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97917 13.3 7.9375C13.0167 7.89583 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3958 6.675 13.6375 6.025C13.8792 5.375 14 4.7 14 4C14 3.3 13.8792 2.625 13.6375 1.975C13.3958 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116667 13.0667 0.0625 13.3 0.0375C13.5333 0.0125 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9542 12.85 13.8625 12.7C13.7708 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5625 10.775 11.3375C9.85833 11.1125 8.93333 11 8 11C7.06667 11 6.14167 11.1125 5.225 11.3375C4.30833 11.5625 3.4 11.9 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z" fill="white"/>
    </svg>,
    neSubsidy: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2 17.5L16 14.7V17H17V13H13V14H15.3L12.5 16.8L13.2 17.5ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V8.7C17.6833 8.55 17.3583 8.42083 17.025 8.3125C16.6917 8.20417 16.35 8.125 16 8.075V2H2V16H8.05C8.1 16.3667 8.17917 16.7167 8.2875 17.05C8.39583 17.3833 8.525 17.7 8.675 18H2ZM2 16V2V8.075V8V16ZM4 14H8.075C8.125 13.65 8.20417 13.3083 8.3125 12.975C8.42083 12.6417 8.54167 12.3167 8.675 12H4V14ZM4 10H10.1C10.6333 9.5 11.2292 9.08333 11.8875 8.75C12.5458 8.41667 13.25 8.19167 14 8.075V8H4V10ZM4 6H14V4H4V6ZM15 20C13.6167 20 12.4375 19.5125 11.4625 18.5375C10.4875 17.5625 10 16.3833 10 15C10 13.6167 10.4875 12.4375 11.4625 11.4625C12.4375 10.4875 13.6167 10 15 10C16.3833 10 17.5625 10.4875 18.5375 11.4625C19.5125 12.4375 20 13.6167 20 15C20 16.3833 19.5125 17.5625 18.5375 18.5375C17.5625 19.5125 16.3833 20 15 20Z" fill="white"/>
    </svg>,
    templateIcon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 14H11V9H6V14ZM6 7H18V2H6V7ZM13 14H18V9H13V14ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H16V20H2Z" fill="white"/>
    </svg>,
    crossIcon : <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.45625 9.5L0.5 8.54375L4.04375 5L0.5 1.45625L1.45625 0.5L5 4.04375L8.54375 0.5L9.5 1.45625L5.95625 5L9.5 8.54375L8.54375 9.5L5 5.95625L1.45625 9.5Z" fill="#A12B31"/>
    </svg>,
    AttachmentIcon: <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 15.5C1.0875 15.5 0.734375 15.3531 0.440625 15.0594C0.146875 14.7656 0 14.4125 0 14V2C0 1.5875 0.146875 1.23438 0.440625 0.940625C0.734375 0.646875 1.0875 0.5 1.5 0.5H8.25L12 4.25V14C12 14.4125 11.8531 14.7656 11.5594 15.0594C11.2656 15.3531 10.9125 15.5 10.5 15.5H1.5ZM1.5 14H10.5V5H7.5V2H1.5V14ZM6 13.25C6.8375 13.25 7.54688 12.9563 8.12813 12.3687C8.70938 11.7812 9 11.075 9 10.25V7.25H7.5V10.25C7.5 10.6625 7.35625 11.0156 7.06875 11.3094C6.78125 11.6031 6.425 11.75 6 11.75C5.5875 11.75 5.23438 11.6031 4.94063 11.3094C4.64687 11.0156 4.5 10.6625 4.5 10.25V6.125C4.5 6.0125 4.5375 5.92188 4.6125 5.85313C4.6875 5.78438 4.775 5.75 4.875 5.75C4.9875 5.75 5.07812 5.78438 5.14688 5.85313C5.21563 5.92188 5.25 6.0125 5.25 6.125V10.25H6.75V6.125C6.75 5.6 6.56875 5.15625 6.20625 4.79375C5.84375 4.43125 5.4 4.25 4.875 4.25C4.35 4.25 3.90625 4.43125 3.54375 4.79375C3.18125 5.15625 3 5.6 3 6.125V10.25C3 11.075 3.29375 11.7812 3.88125 12.3687C4.46875 12.9563 5.175 13.25 6 13.25Z" fill="#0088FF"/>
    </svg>,
    downloadIocn: <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.93431 9.52665L3.1918 5.78752L4.2397 4.70317L6.18581 6.64752V0.552734H7.68282V6.64752L9.62892 4.70317L10.6768 5.78752L6.93431 9.52665ZM2.4433 12.518C2.03162 12.518 1.6792 12.3715 1.38603 12.0786C1.09287 11.7857 0.946289 11.4336 0.946289 11.0223V8.77882H2.4433V11.0223H11.4253V8.77882H12.9223V11.0223C12.9223 11.4336 12.7758 11.7857 12.4826 12.0786C12.1894 12.3715 11.837 12.518 11.4253 12.518H2.4433Z" fill="#0088FF"/>
    </svg>,
    
    filedetails:<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 18.6667H14.6667V12H8V18.6667ZM8 9.33333H24V2.66667H8V9.33333ZM17.3333 18.6667H24V12H17.3333V18.6667ZM8 21.3333C7.26667 21.3333 6.63889 21.0722 6.11667 20.55C5.59444 20.0278 5.33333 19.4 5.33333 18.6667V2.66667C5.33333 1.93333 5.59444 1.30556 6.11667 0.783333C6.63889 0.261111 7.26667 0 8 0H24C24.7333 0 25.3611 0.261111 25.8833 0.783333C26.4056 1.30556 26.6667 1.93333 26.6667 2.66667V18.6667C26.6667 19.4 26.4056 20.0278 25.8833 20.55C25.3611 21.0722 24.7333 21.3333 24 21.3333H8ZM2.66667 26.6667C1.93333 26.6667 1.30556 26.4056 0.783333 25.8833C0.261111 25.3611 0 24.7333 0 24V5.33333H2.66667V24H21.3333V26.6667H2.66667Z" fill="white"/>
    </svg>,

    newdownload:<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9L2.25 5.25L3.3 4.1625L5.25 6.1125V0H6.75V6.1125L8.7 4.1625L9.75 5.25L6 9ZM1.5 12C1.0875 12 0.734375 11.8531 0.440625 11.5594C0.146875 11.2656 0 10.9125 0 10.5V8.25H1.5V10.5H10.5V8.25H12V10.5C12 10.9125 11.8531 11.2656 11.5594 11.5594C11.2656 11.8531 10.9125 12 10.5 12H1.5Z" fill="#0088FF"/>
    </svg>,

    
excelicon:<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_247_13880)">
<path d="M15.4843 1.84375H9.84619V3.68006H11.7114V4.9299H9.84619V6.15144H11.6923V7.38221H9.84619V8.61298H11.6923V9.84375H9.84619V11.0745H11.6923V12.3053H9.84619V14.1514H15.4843C15.768 14.1514 16 13.9108 16 13.6161V2.37913C16 2.08437 15.768 1.84375 15.4843 1.84375ZM14.7693 12.3053H12.3077V11.0745H14.7693V12.3053ZM14.7693 9.84375H12.3077V8.61298H14.7693V9.84375ZM14.7693 7.38221H12.3077V6.15144H14.7693V7.38221ZM14.7693 4.92067H12.3077V3.6899H14.7693V4.92067Z" fill="white"/>
<path d="M0 1.77785V14.2228L9.23077 16V0L0 1.77785ZM5.83877 11.1262L4.76492 9.096C4.72431 9.02031 4.68246 8.88123 4.63877 8.67877H4.62215C4.60185 8.77415 4.55385 8.91938 4.47815 9.11385L3.40062 11.1262H1.72738L3.71385 8.00985L1.89662 4.89292H3.60492L4.496 6.76185C4.56554 6.90954 4.62769 7.08492 4.68308 7.28738H4.70031C4.73538 7.16554 4.80062 6.98462 4.896 6.744L5.88677 4.89231H7.45108L5.58215 7.98277L7.50338 11.1255L5.83877 11.1262Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_247_13880">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
,


    newreporticon:<svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66683 21.9974H16.3335V19.3307H5.66683V21.9974ZM5.66683 16.6641H16.3335V13.9974H5.66683V16.6641ZM3.00016 27.3307C2.26683 27.3307 1.63905 27.0696 1.11683 26.5474C0.594607 26.0252 0.333496 25.3974 0.333496 24.6641V3.33073C0.333496 2.5974 0.594607 1.96962 1.11683 1.4474C1.63905 0.925174 2.26683 0.664062 3.00016 0.664062H13.6668L21.6668 8.66406V24.6641C21.6668 25.3974 21.4057 26.0252 20.8835 26.5474C20.3613 27.0696 19.7335 27.3307 19.0002 27.3307H3.00016ZM12.3335 9.9974V3.33073H3.00016V24.6641H19.0002V9.9974H12.3335Z" fill="white"/>
    </svg>


    
    
}

export default SvgIcons;