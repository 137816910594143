import instance from "../utils/instance";

export const handleGetTemplates = async (params) => {
    return await instance.get('/v1/templates', { params });
};

export const handleDeleteTemplate = async (id) => {
    return await instance.delete(`/v1/templates/${id}`);
};

export const handleGetTemplateById = async (id) => {
    return await instance.get(`/v1/templates/${id}`);
};

export const handleAddTemplate = async (data) => {
    return await instance.post('/v1/templates', data);
};

export const handleEditTemplate = async (id, formData) => {
    formData.append("id", id);
    return await instance.put(`/v1/templates`, formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
export const handleTemplateUpload = async(formData)=>{
    return await instance.post('/v1/templates', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
export const handleDownloadTemplate = async (data) => {
    return await instance.post('/v1/templates/download', data, {
        responseType: 'blob' // Ensure the response is treated as a binary file
    });
};
