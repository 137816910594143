import instance from "../utils/instance";

export const handleGetFinancialYears = async (params) => {
    return await instance.get('/v1/financial-years', {
        params
    });
}

export const handleDeleteFinancialYear = async (id) => {
    return await instance.delete(`/v1/financial-years/${id}`);
}

export const handleGetFinancialYearById = async (id) => {
    return await instance.get(`/v1/financial-years/${id}`);
}

export const handleAddFinancialYear = async (data) => {
    return await instance.post('/v1/financial-years', data);
}

export const handleEditFinancialYear = async (id, data) => {
    data.id = id;
    return await instance.put(`/v1/financial-years`, data);
}
