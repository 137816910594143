import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import toast from 'react-hot-toast';
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SearchForm from "./SearchForm";
import SubsidyTable from "./SubsidyTable";
import SubsidyModal from "./SubsidyModal";
import { getListingData, handleDeleteNonDomesticConsumer, handleEditNonDomesticConsumer } from "../../services/subsidyVendorDataManagment.service";
import SvgIcons from "../../components/SVGIcons"
import moment from "moment";
import Swal from "sweetalert2";
import { getModulePermissions, isAdminUser } from "../../utils/authorisedmodule";

export default function SubsidyVendorDataUploadList(){
    
    const location = useLocation();
    const navigate = useNavigate();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });
    const [modal, setModal] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        state: "",
    });

    const toggle = () => setModal(!modal);
    const companyTitle = JSON.parse(localStorage.getItem("companyTitle"));

    const permission = useRef({ addModule : false, editModule : false, deleteModule : false });
    useEffect(()=>{
        isAdminUser().then(response=>{
            if(response){
                permission.current.addModule = true;
                permission.current.editModule = true;
                permission.current.deleteModule = true;
            }else{
                getModulePermissions("Vendor Subsidy Data Upload").then(response => {
                    if(response.includes("VENDOR_SUBSIDY_DATA_UPLOAD")){
                        permission.current.addModule = true;
                    }
                    if(response.includes("VENDOR_SUBSIDY_DATA_UPLOAD_REVIEW")){
                        permission.current.editModule = true;
                    }
                    if(response.includes("VENDOR_SUBSIDY_DATA_UPLOAD_DELETE")){
                        permission.current.deleteModule = true;
                    }
                }).catch(error => {
                    console.error("Error fetching permissions:", error);
                });
            }
        }).catch(error=>{
            console.error("Error get during to fetch User Type", error);
        })
        
    },[]);

    const editNonDomesticConsumer = async (id) => {
        navigate(`/subsidy-vendor-data/view/${id}`);
    };
    

    const deleteNonDomesticConsumer = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    handleDeleteNonDomesticConsumer(id).then((responseDelete)=>{
                        toast.success("Subsidy Data deleted successfully");
                        dataQuery.refetch();
                    }).catch(()=>{
                        toast.error("File Already Reviewed, you cannot delete this.");
                    })
                } catch (error) {
                    toast.error("File Already Reviewed, you cannot delete this.");
                }
            }else{

            }
        })
    };

    const changeStatus = async (id, currentStatus) => {
        try {
            await handleEditNonDomesticConsumer(id, !currentStatus );
            toast.success("State status updated successfully");
            dataQuery.refetch();
        } catch (error) {
            toast.error("Error updating state status");
        }
    };

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return getListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return getListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });

    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
    }, [dataQuery.data?.data?.totalPages]);

    const columns = React.useMemo(
        () => [
            {
                accessorFn: (row) => row.fileName,
                id: "fileName",
                header: () => "File Name",
            },
            {
                accessorFn: (row) => row.fileType,
                id: "fileType",
                header: () => "Vendor Type",
            },
            {
                accessorFn: (row) => row.financialYear,
                id: "financialYear",
                header: () => "Financial Year",
            },
            {
                accessorFn: (row) => row.month,
                id: "month",
                header: () => "Month",
            },
            {
                accessorFn: (row) => row.gasProducerState,
                id: "saleLocation",
                header: () => "Gas Producer State",
            },
            {
                accessorFn: (row) => row.companyGasProducer !=null ? row.companyGasProducer : '-',
                id: "companyGasProducer",
                header: () => "Gas Producer",
            },
            {
                accessorFn: (row) => row.uploadDate,
                id: "uploadDate",
                header: () => "Upload At",
                cell: (info) => {
                    return (
                        <span>
                            {moment(info.row.original.uploadDate).format(
                                "D/M/YYYY hh:mm A"
                            )}
                        </span>
                    );
                },
            },
            {
                accessorFn: (row) => row.reviewerComment !=null ? row.reviewerComment : '-',
                id: "reviewerComment",
                header: () => "Reviewer Comment",
            },
            
            {
                accessorFn: (row) => row.status,
                id: "state",
                header: () => "Status",
                enableSorting: false,
                cell: (info) => {
                    if(info.row.original.status == 'Pending'){
                        return(<div><p className="pending-label">Pending</p></div>)
                    }
                    if(info.row.original.status == 'Accepted'){
                        return (<div><p className="active-label">Accepted</p></div>)
                    }
                    else{
                        return (
                            <div><p className="rejected-label">Rejected</p></div>
                        )
                    }
                }
            },
            
            {
                id: "actions",
                isAction: true,
                cell: (info) => {
                    return (
                        <div className="d-flex items-center gap-2 justify-content-center">
                            { permission.current.editModule ? 
                            <div  title="View File Data"
                                onClick={() => {
                                    editNonDomesticConsumer(info.row.original.id);
                                }}
                            >
                                <span className=''>{SvgIcons.eyeIcon}</span>
                            </div> : "" }
                            { permission.current.deleteModule ? 
                            <div title="Delete"
                                onClick={() => {
                                    deleteNonDomesticConsumer(info.row.original.id);
                                }}
                            >
                                <span className=''>{SvgIcons.deleteIcon}</span>
                            </div> : "" }
                        </div>
                    );
                },
                header: () => <div className="d-flex justify-content-center">Actions</div>,
                enableSorting: false,
            },
        ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                {/* <Header toggle={toggle} /> */}
                {permission.current.addModule?<Header toggle={toggle} />:""}
                <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <SearchForm filter={filter} setFilter={setFilter} />
                        <SubsidyTable
                            columns={columns}
                            dataQuery={dataQuery}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </Card>
                </div>
            </div>
            <SubsidyModal modal={modal} toggle={toggle} />
        </React.Fragment>
    );
}
