import React from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import FormInput from "../../components/FormInput";
import { HiMiniUsers } from 'react-icons/hi2';
import ReactSelect from '../../components/ReactSelect';
import DateRangePicker from '../../components/DateRangePicker';
import SvgIcons from "../../components/SVGIcons"
const SearchForm = ({ financialYearOptions, companyOptions, filter, setFilter, monthOptions }) => {
    return (
        <div className="theme-card-header px-1 ">
            <Stack
                gap={3}
                className="flex-wrap  pt-3  align-items-start"
            >
                
                <div className="w-100">
                    <Row className="g-3 mb-4 react-dropdownheight-40">
                        
                        <Col>
                            <Row className="align-items-start g-2 justify-content-center mb-4 d-flex custom-new-wrap">
                                
                                <Col sm md="auto" className="d-flex custom-flex" >

                                     <div className=" flex-grow-1 flex-md-grow-0 min-width-155 w-100">
                                        
                                            <ReactSelect
                                                label={""}
                                                id="company"
                                                key="company"
                                                name="company"
                                                class="form-select "
                                                placeholder="All Company"
                                                aria-label="Floating label select example"
                                                options={companyOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        companyId: e.target.value,
                                                        company: e.target.value ? e.target.value : ''
                                                    });
                                                }}
                                                value={filter.company}
                                            />  

                                    </div>
                                </Col>

                               
                                <Col sm md="auto" className="d-flex custom-flex" >
                                    <div className=" flex-grow-1 flex-md-grow-0 min-width-155 w-100">
                                            <ReactSelect
                                                label={""}
                                                id="financialYear"
                                                key="financialYear"
                                                name="financialYear"
                                                class="form-select "
                                                placeholder="All Financial Years"
                                                aria-label="Floating label select example"
                                                options={financialYearOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        financialYearId: e.target.value,
                                                        financialYear: e.target.value ? e.target.value : ''
                                                    });
                                                }}
                                                value={filter.financialYear}
                                            />
                                    </div>   
                                </Col>
                                <Col sm md="auto" className="d-flex custom-flex " >
                                    <div className=" flex-grow-1 flex-md-grow-0  min-width-155 w-100">
                                            <ReactSelect
                                                label={""}
                                                id="month"
                                                key="month"
                                                name="month"
                                                class="form-select "
                                                placeholder="All Months"
                                                aria-label="Floating label select example"
                                                options={monthOptions}
                                                onChange={(e) => {
                                                    setFilter({
                                                        ...filter,
                                                        month: e.target.value,
                                                    });
                                                }}
                                                value={filter.month}
                                            />
                                    </div>
                                </Col>
                                <button type="submit" className="btn btn-primary custom-width-85">

                                        Search

                                        </button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Stack>
        </div>
    );
};

export default SearchForm;
