import instance from "../utils/instance";

export const handleGetConsumerCategories = async (params) => {
    return await instance.get('/v1/consumer-category', {
        params
    });
}

export const handleDeleteConsumerCategory = async (id) => {
    return await instance.delete(`/v1/consumer-category/${id}`);
}

export const handleGetConsumerCategoryById = async (id) => {
    return await instance.get(`/v1/consumer-category/${id}`);
}

export const handleAddConsumerCategory = async (data) => {
    return await instance.post('/v1/consumer-category', data);
}

export const handleEditConsumerCategory = async (id, data) => {
    data.id = id;
    return await instance.put(`/v1/consumer-category`, data);
}

export const handleGetConsumerList = async (params) => {
    return await instance.get('/v1/consumers/list', {
        params
    });
}

export const handleGetConsumerCategoryList = async (params) => {
    return await instance.get('/v1/consumer-category-list', {
        params
    });
}