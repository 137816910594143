import instance from "../utils/instance";

export const getListingData = async (params) => {
    return await instance.get('/v1/dashboard/gas-subsidy-values', {
        params
    });
}

export const getSubsidyListingData = async (params) => {
    return await instance.get('/public-subsidy-data', {
        params
    });
}

export const handleGetRecentNews = async (id) => {
    return await instance.get(`/public/news/recent`);
}

export const handleGetCompany = async (id) => {
    return await instance.get(`/v1/companies`);
}

export const handleGetStates = async (params) => {
    return await instance.get('/v1/state/list', {
        params
    });
}

export const handleGetStateList = async () => {
    return await instance.get(`/v1/state/list`);
}

export const handleGetDistrictById = async (id) => {
    return await instance.get(`/v1/districts/state/${id}`);
}

export const handleGetFinancialYear = async () => {
    return await instance.get(`/v1/financial-years/list`);
}

export const handleGetnewsBySlug = async (slug) => {
    return await instance.get(`/public/news/${slug}`);
}

export const submitContactUs = async (data) => {
    // data.id = id;
    return await instance.post(`/public-contact-us`, data);
}

export const getSubsidyGraphListingData = async (params) => {
    return await instance.get('/public-subsidy-data-graph', {
        params
    });
}


