import { MdDashboard, MdVisibility } from "react-icons/md"

import SvgIcons from "../../components/SVGIcons"
import { getLocalStorage } from "../../utils/storage";

const companyTitle = getLocalStorage("companyTitle");

export const NavItems = [
  {
    id: 1,
    menuName: "Dashboard",
    title: "Dashboard",
    //menuIcon: <MdDashboard size={14} />,
    menuIcon: SvgIcons.dashboardIcon,
    //path: "/",
    path: "/dashboard",
    disabled: false,
    roleName: "Dashboard"
  },
  {
    id: 2,
    menuName: "States",
    title: "Master Management",
    menuIcon: SvgIcons.mastermanage,
    path: '#',
    moduleName: ["States", "Appointment"],
    roleName: "Master Management",
    subMenu: [
      {
        id: 1,
        menuName: "State Master ",
        // menuIcon: SvgIcons.allpatients,
        path: "/states",
        moduleName: ["States", "Appointment"],
        roleName: "State Master"
      },
      {
        id: 2,
        menuName: "District Master ",
        // menuIcon: SvgIcons.allpatients,
        path: "/districts",
        moduleName: ["States", "Appointment"],
        roleName: "District Master"
      },
      {
        id: 3,
        menuName: "Financial Year Master",
        // menuIcon: SvgIcons.allpatients,
        path: "/financial-years",
        moduleName: ["States", "Appointment"],
        roleName: "Financial Year Master"
      },
      {
        id: 4,
        menuName: "Consumer Category Master ",
        // menuIcon: SvgIcons.allpatients,
        path: "/consumer-categories",
        moduleName: ["States", "Appointment"],
        roleName: "Consumer Category Master"
      },
      
    ],
  },
  {
    id: 3,
    menuName: "Users",
    title: "Portal Users Management",
    menuIcon: SvgIcons.userManagementIcon,
    path: '#',
    moduleName: ["States", "Appointment"],
    roleName: "Users",
    subMenu: [
      {
        id: 1,
        menuName: "Portal Users",
       
        menuIcon: SvgIcons.allpatients,
        path: "/users",
        moduleName: ["States", "Appointment"],
        roneName: "users"
      },
      {
        id: 2,
        menuIcon: SvgIcons.allpatients,
        moduleName: ["States", "Appointment"],
        menuName: "Role & Rights",
        title: "Role & Rights",
        path: "/role-rights",
        //moduleName: "Non Domestic Consumer",
        disabled: false,
        roleName: "RoleRights"
      }
    ],
  },
  {
    id: 3,
    menuName: "Non Domestic Consumer",
    title: "Consumers",
    menuIcon: SvgIcons.consumerIcon,
    path: "/non-domestic-consumers",
    //moduleName: "Non Domestic Consumer",
    disabled: false,
    roleName: "Consumer"
  },

  {
    id: 4,
    menuName: "Subsidy Data Management",
    title: "Subsidy Data Management",
    menuIcon: SvgIcons.userManagementIcon,
    path: '#',
    moduleName: ["States", "Appointment"],
    // roleName: "Subsidy Data Management",
    roleName: "NE Subsidy Data Upload",
    subMenu: [
      {
        id: 1,
        menuName: "Subsidy Data Upload",
        title: "Subsidy Data Upload",
        menuIcon: SvgIcons.allpatients,
        path: "/subsidy-data-upload",
        moduleName: ["States", "Appointment"],
        // roleName: "Subsidy Data Upload",
        roleName: "SUBSIDY_DATA_UPLOAD",
      },
      {
        id: 2,
        menuIcon: SvgIcons.allpatients,
        moduleName: ["States", "Appointment"],
        menuName: "Data Review",
        title: "Data Review",
        path: "/data-review",
        // roleName: "Data Review",
        roleName: "SUBSIDY_DATA_REVIEW",
        disabled: false
      }
    ],
  },
  {
    id: 5,
    menuName: "Subsidy Vendor Data Management",
    title: "Subsidy Data Management",
    menuIcon: SvgIcons.userManagementIcon,
    path: '#',
    moduleName: ["States", "Appointment"],
    roleName: "Vendor Subsidy Data Upload",
    subMenu: [
      {
        id: 1,
        menuName: "Subsidy Data Upload",
        title: "Subsidy Data Upload",
        menuIcon: SvgIcons.allpatients,
        path: "/subsidy-vendor-data",
        moduleName: ["States", "Appointment"],
        roleName: "VENDOR_SUBSIDY_DATA_UPLOAD",
      },
      {
        id: 2,
        menuIcon: SvgIcons.allpatients,
        moduleName: ["States", "Appointment"],
        menuName: "Data Review",
        title: "Data Review",
        path: "/vendor-data-review",
        roleName: "VENDOR_SUBSIDY_DATA_UPLOAD_REVIEW",
        disabled: false
      }
    ],
  },
  {
    id: 6,
    menuName: "NE Subsidy Claim Settlement",
    title: "NE Subsidy Claim Statement",
    menuIcon: SvgIcons.neSubsidy,
    path: "/claim-settlement",
    //moduleName: "Non Domestic Consumer",
    disabled: false,
    roleName: "NE Subsidy Claim Statement"
  },
  {
    id: 7,
    menuName: "Template Management",
    title: "Data Upload Template Management",
    menuIcon: SvgIcons.templateIcon,
    path: "/template-management",
    //moduleName: "Non Domestic Consumer",
    disabled: false,
    roleName : "Template Management"
  },
  {
    id: 8,
    menuName: "Reports",
    title: "Reports",
    menuIcon: SvgIcons.newreporticon,
    path: '#',
    moduleName: ["States", "Appointment"],
    roleName: "Reports",
    subMenu: [
      // {
      //   id: 1,
      //   menuName: "Consumer Details",
      //   // menuIcon: SvgIcons.allpatients,
      //   path: "/consumer-report",
      //   moduleName: ["consumer_report"],
      // },
      {
        id: 2,
        // title: "NE Subsidy Claim Report",
        menuName: "Subsidy Claim Report ",
        // menuIcon: SvgIcons.allpatients,
        path: "/report/ne-subsidy-claim",
        moduleName: ["States", "Appointment"],
        roleName: "REPORT_NE_SUBSIDY_DATA",
      },
       {
         id: 3,
         menuName: companyTitle+" Summary",
         // menuIcon: SvgIcons.allpatients,
         path: "/report/oil-india-summary",
         moduleName: ["States", "Appointment"],
         roleName: "REPORT_OIL_INDIA_SUMMARY_DATA",
       },
      {
        id: 5,
        menuName: "Annexure A",
        // menuIcon: SvgIcons.allpatients,
        path: "/report/annexure-a",
        moduleName: ["States", "Appointment"],
        roleName: "REPORT_ANNEXURE_A",
      },
      {
        id: 6,
        menuName: "Annexure B",
        // menuIcon: SvgIcons.allpatients,
        path: "/report/annexure-b",
        moduleName: ["States", "Appointment"],
        roleName: "REPORT_ANNEXURE_B"
      },
      {
        id: 4,
        menuName: "Audit Trails",
        // menuIcon: SvgIcons.allpatients,
        path: "/report/audit-trail",
        moduleName: ["States", "Appointment"],
        roleName: "AUDIT_TRAIL"
      },
      {
        id: 7,
        menuName: "Reconciliation",
        // menuIcon: SvgIcons.allpatients,
        path: "/report/reconciliation",
        moduleName: ["States", "Appointment"],
        roleName: "REPORT_RECONCILIATION"
      },
      
    ],
  },
  
  // {
  //   id: 10,
  //   menuName: "Districts",
  //   title: "Districts",
  //   menuIcon: SvgIcons.goalsIcon,
  //   path: "",
  //   disabled: false
  // },
  // {
  //   id: 11,
  //   menuName: "Financial Year",
  //   title: "Financial Year",
  //   menuIcon: SvgIcons.goalsIcon,
  //   path: "",
  //   disabled: false
  // },
  // {
  //   id: 12,
  //   menuName: "Consumer Category",
  //   title: "Consumer Category",
  //   menuIcon: SvgIcons.goalsIcon,
  //   path: "",
  //   disabled: false
  // }

]
