export const englishLang = {
   
    // HEADER
    "WELCOME": "Welcome",
    "MENU": "Menu",
    "NORTH EAST GAS SUBSIDY SCHEME PORTAL" : "North East Gas Subsidy Scheme Portal",
    "EMPOWERING COMMUNITIES WITH SUBSIDIZED GAS IN NORTH EAST INDIA": "Empowering Communities with Subsidized Gas in North East India",
    "HOME": "Home",
    "ABOUT NE GAS SUBSIDY SCHEME": "About NE Gas Subsidy Scheme",
    "SUBSIDIZED GAS SUPPLY INFORMATION": "Subsidized Gas Supply Information",
    "NEWS & UPDATES": "News & Updates",
    "DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA": "Delivering essential energy to Assam and Tripura",
    "LEARN MORE": "Learn More",
    "ABOUT NORTH EAST GAS SUBSIDY": "About North East Gas Subsidy",
    "SUBMIT": "Submit",
    "LORM IPSUM": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo",
    "CONTACTUS": "Contact Us",
    "MISSION": "Mission",
    "VISION": "Vision",
    "VALUES": "Values",
    "SUBSIDIZED GAS PROGRAM": "Subsidized Gas Program",
    "OVERVIEW": "Scheme Notification",
    "ELIGIBILITY AND BENEFITS": "Major Sectors Benefited by the Scheme",
    "LANGUAGE": "Language",
    "NAME": "Name",
    "EMAIL ADDRESS": "Email Address",
    "SUBJECT": "Subject",
    "MESSAGE": "Message",
    "LOADING": "Loading",
    "HINDI": "Hindi",
    "ENGLISH": "English",
    "ASSAMESE": "Assamese",
    "ALL RIGHTS RESERVED" : "All Rights Reserved",
    "COPYRIGHTS": "Copyrights",
    "LOGIN": "Login",
    "ABOUT_1": "The Current Subsidy Scheme was notified by Ministry of Petroleum and Natural Gas (MoP&NG), Government of India vide Letter No. L.I2015/13/10-GP dated 15.11.2010 w.e.f 01.06.2010.",
    "ABOUT_2": "Under the Scheme, Gas upto a fixed quantitative limit is supplied to designated consumers at subsidized rates (i.e. 60% of the gas price applicable to Gas from Nomination fields of ONGC and OIL.",
    "ABOUT_3": "ONGC and OIL supply subsidized gas to GAIL and AGCL and claim the subsidy amount from the MoP&NG on monthly basis. ",
    "ABOUT_4": "GAIL, AGCL and TNGC further supply the subsidized gas to end consumers like Tea Estates, ",
    "ABOUT_5": "Domestic PNG Consumers, CNG Stations, Power Plants and others through their extensive pipeline network in the North Eastern Region. ",
    "NORTH EAST GAS SUBSIDY SCHEME": "North East Gas Subsidy Scheme",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 1": "(MoP&NG), Government of India vide Letter No. L.I2015/13/10-GP dated 15.11.2010",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 2": "Login",
    "MAJOR 1": "Domestic PNG Consumers through CGDs",
    "MAJOR 2": "CNG Consumers through CNG Stations",
    "MAJOR 3": "Tea Estates",
    "MAJOR 4": "Power Sector",
    "MAJOR 5": "Fertilizer Sector",
    "CONTACTUS DESCRIPTION": "Get in touch with our team",
    "NORTH EAST GAS DESCRIPTION" : "North East Gas Subsidy Scheme – Benefit Details",
    "SLIDE 1": "Empowering Communities in North Eastern India with subsidized Gas",
    "SLIDE 2": "Energizing North Eastern States with clean energy",
    "SLIDE 3": "A Commitment of the Government of India to the North East",
    "ONGC": "ONGC",
    "OIL": "Oil India Ltd",
    "ONGC ADDRESS": "I/c Corporate Commercial ONCG Ltd Deen Dayal Urja Bhawan, 3rd Floor, 5 Nelson Mandela Marg Vasant kunj New Delhi - 110070",
    "OIL ADDRESS": "CGM (F&A) Oil India Ltd Plot No 19, Near Film City Sector 16 Noida 201301",

    "GAS SUBSIDY BENEFIT BY CONSUMER CATEGORY": "Gas Subsidy Benefit by Consumer Category (Rs in Crore)",
    "GAS SUBSIDY BENEFIT BY STATE": "Gas Subsidy Benefit by State (Rs in Crore)",


    
}