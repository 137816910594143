import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";
import toast from 'react-hot-toast';
import React, { useEffect, useState } from "react";
import Header from "./Header";
import SearchForm from "./SearchForm";
import DataReviewTable from "./DataReviewTable";
import DataReviewModal from "./DataReviewModal";
import { getListingData} from "../../services/dataReviewList.service";
import SvgIcons from "../../components/SVGIcons"
import Toggle from "../../components/Toggle";
import moment from "moment";

export default function DataReviewList(){
    
    const location = useLocation();
    const navigate = useNavigate();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });
    const [modal, setModal] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        state: "",
    });

    const toggle = () => setModal(!modal);

    const editNonDomesticConsumer = async (id) => {
        navigate(`/data-review/view/${id}`);
    };

   

    

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }));
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key]);

            if (sorting.length === 0) {
                return getListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return getListingData({
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sort: sorting
                        .map(
                            (sort) => `${sort.id},${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });

    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
    }, [dataQuery.data?.data?.totalPages]);

    const columns = React.useMemo(
        () => [
            {
                accessorFn: (row) => row.fileName,
                id: "fileName",
                header: () => "File Name",
            },
            {
                accessorFn: (row) => row.financialYear,
                id: "financialYear",
                header: () => "Financial Year",
            },
            {
                accessorFn: (row) => row.month,
                id: "month",
                header: () => "Month",
            },
            {
                accessorFn: (row) => row.saleLocation,
                id: "saleLocation",
                header: () => "Sale Location",
            },
            {
                accessorFn: (row) => row.uploadedByName,
                id: "uploadedByName",
                header: () => "Uploaded By",
            },
            {
                accessorFn: (row) => row.uploadDate,
                id: "uploadDate",
                header: () => "Upload At",
                cell: (info) => {
                    return (
                        <span>
                            {moment(info.row.original.uploadDate).format(
                                "D/M/YYYY hh:mm A"
                            )}
                        </span>
                    );
                },
            },
            {
                accessorFn: (row) => row.status,
                id: "status",
                header: () => "Status",
                enableSorting: false,
                cell: (info) => {
                    if(info.row.original.status == 'Pending'){
                        return(<div><p className="pending-label">Pending</p></div>)
                    }
                    if(info.row.original.status == 'Accepted'){
                        return (<div><p className="active-label">Accepted</p></div>)
                    }
                    else{
                        return (
                            <div><p className="rejected-label">Rejected</p></div>
                        )
                    }
                }
            },
            
            {
                id: "actions",
                isAction: true,
                cell: (info) => {
                    if(info.row.original.status == 'Pending'){
                        return (
                            <div className="d-flex items-center gap-2 justify-content-center">
                                <div
                                    onClick={() => {
                                        editNonDomesticConsumer(info.row.original.id);
                                    }}
                                >
                                    <span className=''>{SvgIcons.catalogIcon}</span>
                                </div>
                            </div>
                        );
                    }else{
                        return (
                            <div className="d-flex items-center gap-2 justify-content-center">
                                <div
                                    onClick={() => {
                                        editNonDomesticConsumer(info.row.original.id);
                                    }}
                                >
                                    <span className=''>{SvgIcons.eyeIcon}</span>
                                </div>
                            </div>
                        );
                    }
                    
                },
                header: () => <div className="d-flex justify-content-center">Actions</div>,
                enableSorting: false,
            },
        ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                <Header toggle={toggle} />
                <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <SearchForm filter={filter} setFilter={setFilter} />
                        <DataReviewTable
                            columns={columns}
                            dataQuery={dataQuery}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </Card>
                </div>
            </div>
            <DataReviewModal modal={modal} toggle={toggle} />
        </React.Fragment>
    );
}
