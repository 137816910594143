import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Col, Row } from "react-bootstrap";


export default function FrontLayout({ children }) {
    const [isActiveSidebar, setIsActiveSidebar] = useState(false);

    const toggleSidebarButton = () => {
        setIsActiveSidebar((current) => !current);
    };

    return (
        <main className="mainContentBox">
            <div className="d-flex flex-column h-100 w-100">
                <Header

                    isActiveSidebar={isActiveSidebar}
                    toggleSidebarButton={toggleSidebarButton}
                />
                <div className="flex-grow-1 overflow-hidden w-100">
                    <Row className="gx-0 h-100">
                        {children}
                        
                    </Row>
                </div>
                <Footer/>
            </div>
        </main>
    );
}
