import React from 'react';
import { Button, Stack } from 'react-bootstrap';

const Header = ({ toggle }) => {
    return (
        <div className="contentHeader p-1">
            <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between custom-min-height-42"
            >
                <h1 className="fw-semibold h4 mb-0 fs-22">
                    Goals
                </h1>
                <Button
                    color="info"
                    className="fw-semibold fs-14"
                    onClick={toggle}
                >
                    Add New
                </Button>
            </Stack>
        </div>
    );
};

export default Header;
