import instance from "../utils/instance";

export const getListingData = async (params) => {
    return await instance.get('/v1/vendor-subsidy-data-upload', {
        params
    });
}

export const getListingDataOfUploadedData = async (id,params) => {
    return await instance.get(`/v1/vendor-subsidy-data-upload/${id}/data`, {
        params
    });
}

export const getListingDataOfUploadedDataAnnexureA = async (id,params) => {
    return await instance.get(`/v1/upload-annexure-a/${id}/data`, {
        params
    });
}

export const getListingDataOfUploadedDataAnnexureB = async (id,params) => {
    return await instance.get(`/v1/upload-annexure-b/${id}/data`, {
        params
    });
}

export const handleDeleteNonDomesticConsumer = async (id) => {
    return await instance.delete(`/v1/vendor-subsidy-data-upload/${id}`);
}

export const handleGetSpecificDataById = async (id) => {
    return await instance.get(`/v1/vendor-subsidy-data-upload/${id}`);
}

export const handleAddNonDomesticConsumer = async (data) => {
    return await instance.post('/v1/vendor-subsidy-data-upload', data);
}

export const handleEditNonDomesticConsumer = async (id, data) => {
    data.id = id;
    return await instance.put(`/v1/vendor-subsidy-data-upload`, data);
}

export const handleImportConsumerData = async(formData)=>{
    return await instance.post('/v1/vendor-subsidy-data-upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const handleEditData = async (id, data) => {
    data.id = id;
    return await instance.post(`/v1/vendor-subsidy-data-upload/review`, data);
}

export const handleVendorEditData = async (id, data) => {
    data.id = id;
    return await instance.post(`/v1/vendor-subsidy-data-upload/review`, data);
}


