import React from "react";
import HomePage from "../pages/home";
import Dashboard from "../pages/dashboard";
import DistrictdataPage from "../pages/districtdata";
import NewsupdatedetailsPage from "../pages/newsupdatedetails";
import DashboardPage from "../pages/dashboard";
import NotFoundPage from "../pages/common/NotFoundPage";
import Login from "../pages/authentication/Login";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ResetPassword from "../pages/authentication/ResetPassword";
import ChangePassword from "../pages/Profile/ChangePassword";
import UserList from "../pages/users";
import GoalsList from "../pages/goals";
import StatesList from "../pages/states";
import DistrictsList from "../pages/districts";
import FinancialYearList from "../pages/financial-years";
import ConsumerCategoryList from "../pages/consumer-categories";
//import {FinancialYearPage} from '../pages/financialYear/FinancialYearPage';
import Otp from "../pages/authentication/Otp";
import NonDomesticConsumerList from "../pages/non-domestic-consumers";
import ClaimList from "../pages/claim";
import WebsiteListing from "../pages/website-listing";
import SubsidyGasProgram from "../pages/subsidy-gas-program";
import TemplateManagementList from "../pages/template-management";
import RoleRightsList from "../pages/role-rights";
import SubsidyDataUploadList from "../pages/subsidy-data-upload";
import SubsidyVendorDataUploadList from "../pages/subsidy-vendor-data-upload";
import DataReviewList from "../pages/data-review";
import VendorDataReviewList from "../pages/vendor-data-review";

const Templates = React.lazy(() => import("../pages/templates"));
const AddEditTemplate = React.lazy(() => import("../pages/templates/addEdit"))
const UserForm = React.lazy(() => import("../pages/users/Edit"));

const LlmConnection = React.lazy(() => import("../pages/llm-connection"));
const Settings = React.lazy(() => import("../pages/settings"));
const UserTransactions = React.lazy(() => import("../pages/users/transactions"));


// Saving Challenges
const SavingChallenges = React.lazy(() => import("../pages/saving-challenges"));
const AddEditState = React.lazy(() => import("../pages/states/StateForm"))
const UpdateGailData = React.lazy(() => import("../pages/subsidy-data-upload/UpdateGailData"))
const AddEditDistrict = React.lazy(() => import("../pages/districts/DistrictForm"))
const AddEditFinancialYear = React.lazy(() => import("../pages/financial-years/FinancialYearForm"))
const AddEditConsumerCategory = React.lazy(() => import("../pages/consumer-categories/ConsumerCategoryForm"))
const AddStatePage = React.lazy(() => import("../pages/states/AddStatePage"))
const AddUserPage = React.lazy(() => import("../pages/users/AddUserPage"))
const AddEditNonDomesticConsumer = React.lazy(() => import("../pages/non-domestic-consumers/NonDomesticConsumerForm"));
const ImportConsumers = React.lazy(() => import("../pages/non-domestic-consumers/ImportConsumers"));
const TemplateUploadComponent = React.lazy(() => import("../pages/template-management/TemplateUploadComponent"));
const TemplateManagementForm = React.lazy(() => import("../pages/template-management/TemplateManagementForm"));
const AddClaimPage = React.lazy(() => import("../pages/claim/AddClaimPage"))
const AddEditRoleRights = React.lazy(() => import("../pages/role-rights/RoleRightsForm"))
const ViewVendorSubsidyDataUpload = React.lazy(() => import("../pages/subsidy-vendor-data-upload/SubsidyForm"));
const AddSubsidyDataUpload = React.lazy(() => import("../pages/subsidy-data-upload/SubsidyForm"));
const SubsidyFileUploadDataList = React.lazy(() => import("../pages/subsidy-upload-file-data/index"));
const SubsidyAnnexureAFileUploadDataList = React.lazy(() => import("../pages/subsidy-upload-file-data/indexAnnexureA"));
const SubsidyAnnexureBFileUploadDataList = React.lazy(() => import("../pages/subsidy-upload-file-data/indexAnnexureB"));
const ImportSubsidyVendorDataFile = React.lazy(() => import("../pages/subsidy-vendor-data-upload/ImportSubsidyVendorDataFile"));
const ImportSubsidyDataFile = React.lazy(() => import("../pages/subsidy-data-upload/ImportSubsidyDataFile"));
const SubsidyAnnexureAList = React.lazy(() => import("../pages/subsidy-annexure-a/index"));
const ImportSubsidyAnnexureADataFile = React.lazy(() => import("../pages/subsidy-annexure-a/ImportSubsidyAnnexureAFile"));
const SubsidyAnnexureBList = React.lazy(() => import("../pages/subsidy-annexure-b/index"));
const ImportSubsidyAnnexureBDataFile = React.lazy(() => import("../pages/subsidy-annexure-b/ImportSubsidyAnnexureBFile"));

const AddDataReviewForm = React.lazy(() => import("../pages/data-review/DataReviewForm"));
const VendorDataReviewForm = React.lazy(() => import("../pages/vendor-data-review/VendorDataReviewForm"));
const AnnexureAReport = React.lazy(() => import("../pages/Reports/annexure-a/AnnexureA"));
const AnnexureBReport = React.lazy(() => import("../pages/Reports/annexure-b/AnnexureB"));
const NeSubsidyClaimReport = React.lazy(() => import("../pages/Reports/ne-subsidy-claim/NeSubsidyClaim"));
const NeSubsidyConsumerList = React.lazy(() => import("../pages/Reports/ne-subsidy-claim/NeSubsidyConsumerList"));

const OilIndiaSummaryReport = React.lazy(() => import("../pages/Reports/oil-india-summary/OilIndiaSummary"));
const OilIndiaConsumerList = React.lazy(() => import("../pages/Reports/oil-india-summary/OilIndiaConsumerList"));

const AuditTrailReport = React.lazy(() => import("../pages/Reports/audit-trail"));
const AuditTrailReportView = React.lazy(() => import("../pages/Reports/audit-trail/ViewAuditTrail"));

const ReconciliationReport = React.lazy(() => import("../pages/Reports/reconciliation/Reconciliation"));


const routes = [
    {
        path: "/",
        element: <Dashboard />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/",
        element: <HomePage />,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/home",
        element: <HomePage />,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/subsidized-gas-program",
        element: <SubsidyGasProgram />,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/news-details/:slug",
        element: <NewsupdatedetailsPage/>,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/subsidized-gas-program-listing",
        element: <WebsiteListing />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/districtdata",
        element: <DistrictdataPage />,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/dashboard",
        element: <DashboardPage />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/login",
        element: <Login />,
        isPrivate: false,
        layoutType: "Blank",
    },
    {
        path: "/otp",
        element: <Otp />,
        isPrivate: false,
        layoutType: "Blank",
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        isPrivate: false,
        layoutType: "Blank",
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
        isPrivate: false,
        layoutType: "Blank",
    },
    {
        path: "/change-password",
        element: <ChangePassword />,
        isPrivate: true,
        layoutType: "Auth",
    },
    
    {
        path: "/claim-settlement",
        element: <ClaimList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/claim-settlement/add",
        element: <AddClaimPage isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/users",
        element: <UserList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/goals",
        element: <GoalsList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/states",
        element: <StatesList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/states/add",
        element: <AddStatePage isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/users/add",
        element: <AddUserPage isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/users/edit/:id",
        element: <AddUserPage isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/states/edit/:id",
        element: <AddEditState isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
         path: "/districts",
         element: <DistrictsList />,
         isPrivate: true,
         layoutType: "Auth",
    },
    {
        path: "/districts/add",
        element: <AddEditDistrict isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/districts/edit/:id",
        element: <AddEditDistrict isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/financial-years",
        element: <FinancialYearList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
       path: "/financial-years/add",
       element: <AddEditFinancialYear isEdit={false} />,
       isPrivate: true,
       layoutType: "Auth",
    },
    {
       path: "/financial-years/edit/:id",
       element: <AddEditFinancialYear isEdit={true} />,
       isPrivate: true,
       layoutType: "Auth",
    },
    {
        path: "/consumer-categories",
        element: <ConsumerCategoryList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/consumer-categories/add",
        element: <AddEditConsumerCategory isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/consumer-categories/edit/:id",
        element: <AddEditConsumerCategory isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/templates",
        element: <Templates />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/templates/add",
        element: <AddEditTemplate isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/templates/edit",
        element: <AddEditTemplate isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/llm-connection",
        element: <LlmConnection />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/users/:id",
        element: <UserForm isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/users/:id/transactions",
        element: <UserTransactions />,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "/settings",
        element: <Settings />,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "/cms",
        element: <Settings />,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "/saving-challenges",
        element: <SavingChallenges />,
        isPrivate: true,
        layoutType: "Auth",

    },

    {
        path: "/non-domestic-consumers",
        element: <NonDomesticConsumerList />,
        isPrivate: true,
        layoutType: "Auth",

    },
    {
        path: "/non-domestic-consumers/add",
        element: <AddEditNonDomesticConsumer isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/non-domestic-consumers/edit/:id",
        element: <AddEditNonDomesticConsumer isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/non-domestic-consumers/import",
        element: <ImportConsumers/>,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "/subsidy-vendor-data/view/:id",
        element: <ViewVendorSubsidyDataUpload isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-vendor-data",
        element: <SubsidyVendorDataUploadList />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-vendor-data/upload",
        element: <ImportSubsidyVendorDataFile isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/vendor-data-review",
        element: <VendorDataReviewList isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/vendor-data-review/view/:id",
        element: <VendorDataReviewForm isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-data-upload",
        element: <SubsidyDataUploadList />,
        isPrivate: true,
        layoutType: "Auth",

    },
    {
        path: "/subsidy-data-upload/add",
        element: <AddSubsidyDataUpload isEdit={false} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-data-upload/upload-data-file/view/:id",
        element: <SubsidyFileUploadDataList isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-data-upload/ongc-data-file/update/:id",
        element: <UpdateGailData isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "subsidy-data-upload/annexure-a/upload-data-file/view/:id",
        element: <SubsidyAnnexureAFileUploadDataList isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "subsidy-data-upload/annexure-b/upload-data-file/view/:id",
        element: <SubsidyAnnexureBFileUploadDataList isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-data-upload/upload-data",
        element: <ImportSubsidyDataFile/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    
    {
        path: "/subsidy-data-upload/view/:id",
        element: <AddSubsidyDataUpload isEdit={true} />,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-annexure-a-list",
        element: <SubsidyAnnexureAList/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-annexure-a/upload-data",
        element: <ImportSubsidyAnnexureADataFile/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/subsidy-annexure-b/upload-data",
        element: <ImportSubsidyAnnexureBDataFile/>,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "/template-management",
        element: <TemplateManagementList/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/template-management/add",
        element: <TemplateUploadComponent isEdit={false}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/template-management/edit/:id",
        element: <TemplateUploadComponent isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/template-management/view/:id",
        element: <TemplateManagementForm/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/role-rights",
        element: <RoleRightsList/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/role-rights/add",
        element: <AddEditRoleRights isEdit={false}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/role-rights/edit/:id",
        element: <AddEditRoleRights isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },

    {
        path: "*",
        element: <HomePage />,
        isPrivate: false,
        layoutType: "FrontAuth",
    },
    {
        path: "/data-review",
        element: <DataReviewList isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path: "/data-review/view/:id",
        element: <AddDataReviewForm isEdit={true}/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/annexure-a",
        element : <AnnexureAReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/annexure-b",
        element : <AnnexureBReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/ne-subsidy-claim",
        element : <NeSubsidyClaimReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/ne-subsidy-claim/consumer/:gasProducerStateId/:month/:financialYearId/:consumerKey/:consumerName",
        element : <NeSubsidyConsumerList/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/oil-india-summary",
        element : <OilIndiaSummaryReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/oil-india-summary/consumer/:gasProducerStateId/:month/:financialYearId/:consumerKey/:consumerName",
        element : <OilIndiaConsumerList/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/audit-trail",
        element : <AuditTrailReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/audit-trail/view/:id",
        element : <AuditTrailReportView/>,
        isPrivate: true,
        layoutType: "Auth",
    },
    {
        path:"/report/reconciliation",
        element : <ReconciliationReport/>,
        isPrivate: true,
        layoutType: "Auth",
    },
];

export default routes;