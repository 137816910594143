import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Stack } from "reactstrap";
import { HiMiniUsers } from "react-icons/hi2";

import Header from "./Header";
import SearchForm from "./SearchForm";
import GoalsTable from "./GoalsTable";
import GoalsModal from "./GoalModal";
import {
    handleGetGoals,
} from "../../services/goal.service";


export default function GoalsList() {
    const location = useLocation();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [pagination, setPagination] = useState({
        pageIndex: params.page ? parseInt(params.page) - 1 : 0,
        pageSize: params.limit ? parseInt(params.limit) : 10,
    });
    const [modal, setModal] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
    });

    const toggle = () => setModal(!modal);

    const dataQuery = useQuery({
        queryKey: ["data", pagination, sorting, filter],
        queryFn: () => {
            const filterObj = qs.parse(qs.stringify(filter, { skipNulls: true }))
            Object.keys(filterObj).forEach(key => filterObj[key] === "" && delete filterObj[key])

            if (sorting.length === 0) {
                return handleGetGoals({
                    page: pagination.pageIndex + 1,
                    limit: pagination.pageSize,
                    ...filterObj,
                });
            } else {
                return handleGetGoals({
                    page: pagination.pageIndex + 1,
                    limit: pagination.pageSize,
                    sortBy: sorting
                        .map(
                            (sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`
                        )
                        .join(","),
                    ...filterObj,
                });
            }
        },
    });

    useEffect(() => {
        if (dataQuery.data?.data?.totalPages < pagination.pageIndex + 1) {
            setPagination({
                pageIndex: dataQuery.data?.data?.totalPages - 1,
                pageSize: 10,
            });
        }
    }, [dataQuery.data?.data?.totalPages]);

    const columns = React.useMemo(
        () => [
            {
                accessorFn: (row) => row.title,
                id: "title",
                header: () => "Goals",
            },
            {
                accessorFn: (row) => row.amount,
                id: "amount",
                header: () => "Amount",
            },
            {
                id: "actions",
                isAction: true,
                cell: (info) => {
                    return (
                        <div className="d-flex items-center gap-2 justify-content-center">
                            <div
                                onClick={() => {
                                    toggle()
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.99878 17.4613V20.5013C2.99878 20.7813 3.21878 21.0013 3.49878 21.0013H6.53878C6.66878 21.0013 6.79878 20.9513 6.88878 20.8513L17.8088 9.94128L14.0588 6.19128L3.14878 17.1013C3.04878 17.2013 2.99878 17.3213 2.99878 17.4613ZM20.7088 7.04128C21.0988 6.65128 21.0988 6.02128 20.7088 5.63128L18.3688 3.29128C17.9788 2.90128 17.3488 2.90128 16.9588 3.29128L15.1288 5.12128L18.8788 8.87128L20.7088 7.04128Z"
                                        fill="#2F61A8"
                                    />
                                </svg>
                            </div>
                        </div>
                    );
                },
                header: () => <div className="d-flex justify-content-center">Actions</div>,
                enableSorting: false,
            },
        ],
        []
    );

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
    }, [filter]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                <Header toggle={toggle} />
                <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
                    <Card className="h-100 bg-white shadow-lg border-0 theme-card-cover">
                        <SearchForm filter={filter} setFilter={setFilter} />
                        <GoalsTable
                            columns={columns}
                            dataQuery={dataQuery}
                            pagination={pagination}
                            setPagination={setPagination}
                            sorting={sorting}
                            setSorting={setSorting}
                        />
                    </Card>
                </div>
            </div>
            <GoalsModal modal={modal} toggle={toggle} />
        </React.Fragment>
    );
}
