import React from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = ({ toggle, isListEmpty }) => {
    return (
        <div className="contentHeader p-1">
            <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between custom-min-height-42"
            >
                <h1 className="fw-semibold h4 mb-0 fs-22">
                Data Upload Template Management
                </h1>
                {/* <Button
                    color="primary"
                    className="fw-semibold fs-14"
                    onClick={handleAddNewClick}
                >
                    Add New
                </Button> */}
            </Stack>
        </div>
    );
};

export default Header;
