export const assameseLang = {
   
    // HEADER
    "WELCOME": "স্বাগতম",
    "MENU": "মেনু",
    "NORTH EAST GAS SUBSIDY SCHEME PORTAL" : "উত্তৰ-পূৱ গেছ ছাবচিডি যোজনা প’ৰ্টেল",
    "EMPOWERING COMMUNITIES WITH SUBSIDIZED GAS IN NORTH EAST INDIA": "উত্তৰ-পূব ভাৰতৰ সমাজক ছাবচিডি গেছৰ দ্বাৰা সক্ষম কৰা",
    "HOME": "ঘৰ",
    "ABOUT NE GAS SUBSIDY SCHEME": "উত্তৰ-পূৱ গেছ ছাবচিডি যোজনাৰ বিষয়ে",
    "SUBSIDIZED GAS SUPPLY INFORMATION": "ছাবচিডি গেছ যোগান তথ্য",
    "NEWS & UPDATES": "সংবাদ আৰু আপডেট",
    "DELIVERING ESSENTIAL ENERGY TO ASSAM AND TRIPURA": "অসম আৰু ত্ৰিপুৰালৈ প্রয়োজনীয় শক্তি যোগান ধৰা",
    "LEARN MORE": "অধিক জানক",
    "ABOUT NORTH EAST GAS SUBSIDY": "উত্তৰ-পূৱ গেছ ছাবচিডিৰ বিষয়ে",
    "SUBMIT": "জমা দিয়ক",
    "LORM IPSUM": "কেইবাটাও ক্ষেত্ৰত লক্ষ্য ৰাখি, প্ৰশিক্ষণ প্ৰক্ৰিয়া অনুসৰণ কৰাটো গুৰুত্বপূর্ণ, কিন্তু মই একেটা সময়তে কৰি আছোঁ",
    "CONTACTUS": "যোগাযোগ কৰক",
    "MISSION": "মিছন",
    "VISION": "ভিশন",
    "VALUES": "মূল্যবোৰ",
    "SUBSIDIZED GAS PROGRAM": "ছাবচিডি গেছ প্ৰকল্প",
    "OVERVIEW": "যোজনা বিজ্ঞপ্তি",
    "ELIGIBILITY AND BENEFITS": "যোজনাৰ অধীনত সুবিধাপ্ৰাপ্ত মুখ্য ক্ষেত্ৰসমূহ",
    "LANGUAGE": "ভাষা",
    "NAME": "নাম",
    "EMAIL ADDRESS": "ইমেইল ঠিকনা",
    "SUBJECT": "বিষয়",
    "MESSAGE": "বার্তা",
    "LOADING": "ল'ডিং",
    "HINDI": "হিন্দী",
    "ENGLISH": "ইংৰাজী",
    "ASSAMESE": "অসমীয়া",
    "ALL RIGHTS RESERVED" : "সকলো অধিকাৰ সুৰক্ষিত",
    "COPYRIGHTS": "কপিৰাইট",
    "LOGIN": "লগিন",
    "ABOUT_1": "বৰ্তমানৰ ৰাজসাহায্য আঁচনিখনৰ বিষয়ে ভাৰত চৰকাৰৰ পেট্ৰলিয়াম আৰু প্ৰাকৃতিক গেছ মন্ত্ৰালয়ে (MoPNG) vide Letter No. L.12015/13/10-GP dated 15.11.2010 w.e.f 01.06.2010 ৰ জৰিয়তে অৱগত কৰিছিল।",
    "ABOUT_2": "আঁচনিখনৰ অধীনত নিৰ্দিষ্ট পৰিমাণগত সীমালৈকে গেছ ৰাজসাহায্যযুক্ত হাৰত (অৰ্থাৎ অ’এনজিচি আৰু অইলৰ মনোনয়ন ক্ষেত্ৰৰ পৰা গেছৰ ক্ষেত্ৰত প্ৰযোজ্য গেছৰ মূল্যৰ ৬০%) যোগান ধৰা হয়।",
    "ABOUT_3": "অ’ এন জি চি আৰু অইলে গেইল আৰু এ জি চি এলক ৰাজসাহায্যযুক্ত গেছ যোগান ধৰে আৰু মাহেকীয়া ভিত্তিত এম অ’ পি এণ্ড এন জিৰ পৰা ৰাজসাহায্যৰ পৰিমাণ দাবী কৰে।",
    "ABOUT_4": "গেইল, এজিচিএল আৰু টিএনজিচিয়ে চাহ বাগিচা, ২০১৯ৰ দৰে শেষ গ্ৰাহকক ৰাজসাহায্যযুক্ত গেছ অধিক যোগান ধৰে।",
    "ABOUT_5": "উত্তৰ-পূব অঞ্চলত তেওঁলোকৰ বিস্তৃত পাইপলাইন নেটৱৰ্কৰ জৰিয়তে ঘৰুৱা পিএনজি গ্ৰাহক, চিএনজি ষ্টেচন, বিদ্যুৎ কেন্দ্ৰ আদি।",
    "NORTH EAST GAS SUBSIDY SCHEME": "উত্তৰ-পূব গেছ ছাবচিডি যোজনা",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 1": "(MoP&NG), ভাৰত চৰকাৰে ১৫/১১/২০১০ তাৰিখে পঞ্জীয়ন নম্বৰ L.I2015/13/10-GP ৰ মাধ্যমে অবিহিত কৰিছে।",
    "NORTH EAST GAS SUBSIDY SCHEME DESCRIPTION 2": "লগিন",
    "MAJOR 1": "CGD সমূহৰ মাধ্যমে ঘৰুৱা PNG গ্ৰাহক",
    "MAJOR 2": "CNG ষ্টেচনৰ মাধ্যমে CNG গ্ৰাহক",
    "MAJOR 3": "চাহ বাগিচা",
    "MAJOR 4": "বিদ্যুৎ খণ্ড",
    "MAJOR 5": "সাৰ খণ্ড",
    "CONTACTUS DESCRIPTION": "আমাৰ দলেৰে যোগাযোগ কৰক",
    "NORTH EAST GAS DESCRIPTION" : "উত্তৰ-পূব গেছ ছাবচিডি যোজনা – সুবিধা বিৱৰণ",
    "SLIDE 1": "উত্তৰ-পূব ভাৰতৰ সমাজক ছাবচিডি গেছৰ দ্বাৰা সক্ষম কৰা",
    "SLIDE 2": "পৰিষ্কাৰ শক্তিৰে উত্তৰ-পূব ৰাজ্যসমূহক শক্তিশালী কৰা",
    "SLIDE 3": "উত্তৰ-পূবলৈ ভাৰত চৰকাৰৰ এটা প্ৰতিশ্ৰুতি",
    "ONGC": "ONGC",
    "OIL": "অইল ইণ্ডিয়া লিমিটেড",
    "ONGC ADDRESS": "আই/চি কৰ্পৰেট কমাৰ্চিয়েল অ'এনচিজি লিমিটেড দীন দয়াল উৰ্জা ভৱন, ৩য় মহলা, ৫ নেলচন মেণ্ডেলা মাৰ্গ বসন্ত কুঞ্জ নতুন দিল্লী - ১১০০৭০",
    "OIL ADDRESS": "চিজিএম (এফ এণ্ড এ) অইল ইণ্ডিয়া লিমিটেডৰ প্লট নং ১৯, ফিল্ম চিটি ছেক্টৰ ১৬ নয়ডাৰ ওচৰত ২০১৩০১",

    "GAS SUBSIDY BENEFIT BY CONSUMER CATEGORY": "গ্ৰাহক শ্ৰেণী অনুসৰি গেছ ছাবচিডি সুবিধা (কোটি টকাত)",
    "GAS SUBSIDY BENEFIT BY STATE": "ৰাজ্য অনুসৰি গেছ ছাবচিডি সুবিধা (টকাত)",
}
