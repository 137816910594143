import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getLocalStorage } from '../../utils/storage';

const Header = ({ toggle, addModule, addModuleA, addModuleB }) => {
    const navigate = useNavigate();
    const loginUser = getLocalStorage("companyTitle");
    return (
        <div className="contentHeader p-1">
            <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between custom-min-height-42"
            >
                <h1 className="fw-semibold h4 mb-0 fs-22">
                    Subsidy Data Management
                </h1>
                <div>
                    {addModuleA ? <Link to={`/subsidy-annexure-a/upload-data`} className="fw-semibold me-2 fs-14  bg-info text-white text-decoration-none rounded-2 p-2 px-3 text-center" type="button" variant="info" size="sm">Upload Annexure A</Link> : ''}
                    {addModuleB ? <Link to={`/subsidy-annexure-b/upload-data`} className="fw-semibold me-2 fs-14  bg-info text-white text-decoration-none rounded-2 p-2 px-3 text-center" type="button" variant="info" size="sm">Upload Annexure B</Link> : ''}
                    {loginUser!='GAIL' && addModule ? <Link to={`/subsidy-data-upload/upload-data`} className="fw-semibold fs-14 custom-min-width-100 bg-info text-white text-decoration-none rounded-2 p-2 text-center" type="button" variant="info" size="sm">Upload Data File</Link> : ''}

                </div>
            </Stack>
        </div>
    );
};

export default Header;
